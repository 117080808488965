import React, { useState,useEffect } from 'react';
import { useMyContext } from '../../../context/MyContext';
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { PiWarehouseFill } from "react-icons/pi";
import { toast } from 'react-hot-toast';
import '../../../css/custom-scrollbar.css';
import { Addwarehouse, Base_Url,getpincode } from '../../../config/config';
import { useNavigate } from 'react-router-dom';


const schema = z.object({
  facilityName: z.string()
    .min(1, "Facility name is required")
    .regex(/^[A-Za-z\s]+$/, "Facility name must only contain letters and spaces"),
  // pincode: z.string()
  //   .min(6, "Pincode should be at least 6 characters")
  //   .max(6, "Pincode should be at most 6 characters")
  //   .regex(/^\d+$/, "Pincode must be numeric"),
  contactPerson: z.string()
    .min(1, "Contact Person  is required")
    .regex(/^[A-Za-z\s]+$/, " Contact Person must only contain letters and spaces"),
  // city: z.string()
  //   .min(1, "City is required")
  //   .regex(/^[A-Za-z\s]+$/, "City must only contain letters and spaces"),
  phone: z.string()
    .length(10, "Phone number must be exactly 10 digits")
    .regex(/^\d+$/, "Phone number must be numeric"),
  // state: z.string()
  //   .min(1, "State is required")
  //   .regex(/^[A-Za-z\s]+$/, "State must only contain letters and spaces"),
  pincode: z.string()
  .min(6, "Pincode should be exactly 6 characters")
  .max(6, "Pincode should be exactly 6 characters")
  .regex(/^\d+$/, "Pincode must be numeric"),
city: z.string().min(1, "City is required").regex(/^[a-zA-Z\s]+$/, "City must only contain alphabetic characters"),
state: z.string().min(1, "State is required").regex(/^[a-zA-Z\s]+$/, "State must only contain alphabetic characters"),
  email: z.string().email("Invalid email address"),
  address: z.string().min(1, "Address is required"),
  returnAddress: z.string().optional(),
  returnState: z.string().optional(),
  returnCity: z.string().optional(),
  returnCountry: z.string().optional(),
  returnPincode: z.string().optional().refine(value => value === undefined || /^\d*$/.test(value), "Return pincode must be numeric"),
});


const AddWarehouse = () => {
  const [showReturnAddress, setShowReturnAddress] = useState(false);
  const { isToggleSidebar, isDarkMode } = useMyContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm({
    resolver: zodResolver(schema),
  });


  const handleInputChange = (event) => {
    const { value, id } = event.target;

    if (['pincode', 'phone', 'return-pincode'].includes(id)) {
      const filteredValue = value.replace(/[^0-9]/g, '');
      event.target.value = filteredValue;
    }

    if (['facility-name', 'city', 'contact-person', 'state', 'returnState', 'returnCity', 'returnCountry', 'country'].includes(id)) {
      const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
      event.target.value = filteredValue;
    }
  };

  const navigate = useNavigate();
  const naviagation = () =>{
    navigate('/dashboard');
  }

  const fetchPincodeData = async (pincode) => {
      const token = localStorage.getItem('token');
      const cus_id = localStorage.getItem('cus_id');
      const urlgetPincode = `${Base_Url}${getpincode}?pincode=${pincode}`;
      try {
          // const response = await fetch(`http://192.168.1.36/backendci4/api/getpincode?pincode=${pincode}`, {
          const response = await fetch(urlgetPincode, {
              method: 'GET',
              headers: {
                  // 'token': "eyJ1c2VyX2lkIjoiNTkiLCJleHBpcmVzX2F0IjoxNzI4NzEzMzM4fQ==",
                  'token': token,
                  'cus_id': cus_id,
                  'Content-Type': 'application/json'
              }
          });


          const data = await response.json();
          if (data && data.status === "SUCCESS") {
              let { city, state } = data.data[0];

              city = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();
              state = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase();

              setValue("city", city);
              setValue("state", state);
              setValue("address",city + " " + state);
          }


          else if (data && data.message === "Unauthorized access - Invalid token") {
              toast.error(data.message + " Please login Again");
          }

          else if (data && data.status === "FAIL") {
              toast.error("Something Went Wrong Please Try Again With Another Pincode");
          }


          else {
              toast.error("Failed to fetch city and state data");
          }
      } catch (error) {
          console.error('Error fetching pincode data:', error);
          toast.error("Error fetching pincode data");
      }
  };

  const pincode = watch("pincode");

  useEffect(() => {
      if (pincode && pincode.length === 6) {
          fetchPincodeData(pincode);
      }
  }, [pincode]);
  

  const onSubmit = async (data,event) => {
    event.preventDefault();
    const token = localStorage.getItem('token');
    const cusid = localStorage.getItem('cus_id');
    try {
      const payload = {
        name: data.facilityName,
        email: data.email,
        phone: data.phone,
        address: data.address,
        pincode: data.pincode,
        city: data.city,
        contact_person: data.contactPerson,
        state: data.state,
        country: "India",
        customer_id: cusid,
        return_warehouse: showReturnAddress ? "true" : "false",
        return_address: data.returnAddress || '',
        return_city: showReturnAddress ? "India" : '',
        return_state: data.returnState || '',
        return_country: data.returnCountry || '',
        return_pincode: data.returnPincode || '',
      };

      const response = await axios.post(Base_Url+Addwarehouse, payload, {
        headers: {
          'token': token,
          'cus_id': cusid,
          'Content-Type': 'application/json',
        
        },
      });

      if (response.status === 200) {
        toast.success('Warehouse added successfully!');
        navigate("shipments/all");
        reset();
      } else {
        throw new Error(response.data.message || 'Failed to save warehouse');
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.message || 'An error occurred while saving the warehouse';
      toast.error(errorMessage);
    }
  };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768 
                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }
          >
            <Sidebar />
          </div>
        )}

        <div className={`p-4 md:p-6 min-h-[50vh] custom-scrollbar overflow-y-auto dark:bg-[rgba(1,14,78,0.96)] ${isToggleSidebar ? 'ml-0' : 'mr-0'} ${isToggleSidebar ? 'w-full' : 'w-full'} mb-8 md:mb-0 `}>
        <button onClick={naviagation} 
        className="flex justify-center w-full items-center hover:bg-purple-700 mb-6 bg-customPurple text-white px-4 py-2 rounded-md hover:bg-customPurple-dark transition duration-300">
              <PiWarehouseFill className="text-3xl text-white mr-2" />
              <h2 className="text-2xl font-bold text-center">Add New Pickup Location</h2>
            </button>
          <form onSubmit={handleSubmit(onSubmit)} className="  dark:bg-[rgba(1,14,78,0.96)] dark:text-white shadow-custom-light  dark:shadow-custom-dark rounded-custom  px-8 pt-6 pb-8 mb-4 font-sans">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-bold mb-2" htmlFor="facility-name">
                  Facility Name
                </label>
                <input
                  className={` appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline  shadow-custom-light rounded-custom ${errors.facilityName ? 'border-red-500' : ''} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                  id="facility-name"
                  type="text"
                  placeholder="Enter name"
                  {...register('facilityName')}
                  onChange={handleInputChange}
                />
                {errors.facilityName && <p className="text-red-500 text-xs italic">{errors.facilityName.message}</p>}
              </div>
              <div>
                <label className="block text-sm font-bold mb-2" htmlFor="pincode">
                  Pincode
                </label>
                <input
                  className={` appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline  shadow-custom-light rounded-custom ${errors.pincode ? 'border-red-500' : ''} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                  id="pincode"
                  type="text"
                  maxLength="6"
                  placeholder="Enter pincode"
                  {...register('pincode')}
                  // onChange={handleInputChange}
                />
                {errors.pincode && <p className="text-red-500 text-xs italic">{errors.pincode.message}</p>}
              </div>
              <div>
                <label className="block text-sm font-bold mb-2" htmlFor="contact-person">
                  Contact Person
                </label>
                <input
                  className={` appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline  shadow-custom-light rounded-custom ${errors.contactPerson ? 'border-red-500' : ''} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                  id="contact-person"
                  type="text"
                  placeholder="Enter contact person name"
                  {...register('contactPerson')}
                  onChange={handleInputChange}
                />
                {errors.contactPerson && <p className="text-red-500 text-xs italic">{errors.contactPerson.message}</p>}
              </div>

              <div>
                <label className="block text-sm font-bold mb-2" htmlFor="city">
                  City
                </label>
                <input
                  className={` appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline  shadow-custom-light rounded-custom ${errors.city ? 'border-red-500' : ''} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                  id="city"
                  type="text"
                  placeholder="Enter City"
                  {...register('city')}
                  onChange={handleInputChange}
                />
                {errors.city && <p className="text-red-500 text-xs italic">{errors.city.message}</p>}
              </div>
              <div>
                <label className="block text-sm font-bold mb-2" htmlFor="phone">
                  Pickup location phone
                </label>
                <input
                  className={` appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline  shadow-custom-light rounded-custom ${errors.phone ? 'border-red-500' : ''} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                  id="phone"
                  type="tel"
                  maxLength="10"
                  placeholder="Enter 10 digit mobile no."
                  {...register('phone')}
                  onChange={handleInputChange}
                />
                {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone.message}</p>}
              </div>
              <div>
                <label className="block text-sm font-bold mb-2" htmlFor="state">
                  State
                </label>
                <input
                  className={` appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline  shadow-custom-light rounded-custom ${errors.state ? 'border-red-500' : ''} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                  id="state"
                  type="text"
                  placeholder="Enter state"
                  {...register('state')}
                  onChange={handleInputChange}
                />
                {errors.state && <p className="text-red-500 text-xs italic">{errors.state.message}</p>}
              </div>

              <div>
                <label className="block text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  className={` appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline  shadow-custom-light rounded-custom ${errors.email ? 'border-red-500' : ''} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                  id="email"
                  type="email"
                  placeholder="Enter email"
                  {...register('email')}
                />
                {errors.email && <p className="text-red-500 text-xs italic">{errors.email.message}</p>}
              </div>
              <div>
                <label className="block text-sm font-bold mb-2" htmlFor="country">
                  Country
                </label>
                <input
                  className={`placeholder-slate-600 appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline  shadow-custom-light rounded-custom ${errors.country ? 'border-red-500' : ''} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                  id="country"
                  type="text"
                  disabled
                  placeholder="India"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-bold mb-2" htmlFor="address">
                  Address
                </label>
                <input
                  className={` appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline  shadow-custom-light rounded-custom ${errors.address ? 'border-red-500' : ''} 
                ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                  id="address"
                  type="text"
                  placeholder="Enter complete address"
                  {...register('address')}
                />
                {errors.address && <p className="text-red-500 text-xs italic">{errors.address.message}</p>}
              </div>
            </div>

            <div className="mt-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={showReturnAddress}
                  onChange={(e) => setShowReturnAddress(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 font-bold">Different return address</span>
              </label>
            </div>

            {showReturnAddress && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div>
                  <label className="block text-sm font-bold mb-2" htmlFor="return-address">
                    Return Address
                  </label>
                  <input
                    className={`shadow-custom-light rounded-custom  appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                    id="returnAddress"
                    type="text"
                    placeholder="Enter return address"
                    {...register('returnAddress')}
                  />
                </div>
                <div>
                  <label className="block text-sm font-bold mb-2" htmlFor="return-country">
                    Return Country
                  </label>
                  <input
                    className={`placeholder-slate-600 shadow-custom-light rounded-custom  appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                    id="returnCountry"
                    type="tel"
                    disabled
                    placeholder="India"
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label className="block text-sm font-bold mb-2" htmlFor="return-pincode">
                    Return Pincode
                  </label>
                  <input
                    className={`shadow-custom-light rounded-custom  appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                    id="returnPincode"
                    type="text"
                    maxLength="6"
                    placeholder="Enter return pincode"
                    {...register('returnPincode')}
                    onChange={handleInputChange}

                  />
                </div>
                <div>
                  <label className="block text-sm font-bold mb-2" htmlFor="return-city">
                    Return City
                  </label>
                  <input
                    className={`shadow-custom-light rounded-custom  appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                    id="returnCity"
                    type="text"
                    placeholder="Enter return City"
                    {...register('returnCity')}
                    onChange={handleInputChange}

                  />
                </div>
                <div>
                  <label className="block text-sm font-bold mb-2" htmlFor="return-state">
                    Return State
                  </label>
                  <input
                    className={`shadow-custom-light rounded-custom  appearance-none border  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                    id="returnState"
                    type="text"
                    placeholder="Enter return State"
                    {...register('returnState')}
                    onChange={handleInputChange}

                  />
                </div>

              </div>
            )}

            <div className="flex items-center justify-center mt-6">
              <button
                className="bg-customPurple hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddWarehouse;
