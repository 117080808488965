import React from 'react';
import { Camera, Save } from 'lucide-react';
import Sidebar from '../../component/sidebar/sidebar';
import { useMyContext } from '../../context/MyContext';
import NavBar from '../../component/navBar/navBar';
import '../../css/custom-scrollbar.css';

const UserProfileEdit = () => {
    const {isDarkMode, isToggleSidebar} = useMyContext();

    return (
        <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-gray-100'}`}>
            <div className="z-10">
                <NavBar />
            </div>

            <div className="flex flex-1 overflow-hidden">
                {isToggleSidebar && (
                    <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] md:bg-transparent mt-4 md:w-64 md:z-20`}
                    style={
                        window.innerWidth < 768 
                        ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                        : {}
                    }>
                        <Sidebar />
                    </div>
                )}
        


        <div className='rounded-custom shadow-custom-light dark:shadow-custom-dark overflow-y-auto w-full custom-scrollbar  mt-4 mr-3'>
                <div className="flex-1 overflow-y-auto p-4 md:p-6 lg:p-8 mt-3">
                    <div className="max-w-4xl mx-auto bg-white dark:bg-[rgba(1,14,78,0.96)] rounded-custom custom-scrollbar shadow-custom-light dark:shadow-custom-dark overflow-hidden">
                        <div className="px-6 py-4 bg-gray-50 dark:bg-[rgba(1,14,78,0.96)] border-b">
                            <h1 className="text-2xl font-bold  dark:bg-[rgba(1,14,78,0.96)]">Edit Profile</h1>
                        </div>
                        
                        <div className="p-6">
                            <div className="mb-8">
                                <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200">Public Information</h2>
                                <div className="flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-6">
                                    <div className="relative">
                                        <img src="/assets/images/invoice1.png" alt="Profile" className="w-56 mt-16 h-48 rounded-full object-cover border-4 border-white dark:border-gray-700 shadow-custom-light dark:shadow-custom-dark" />
                                        <button className="absolute bottom-0 right-0 bg-customPurple text-white p-2 rounded-full shadow-lg hover:bg-purple-700 transition duration-300">
                                            <Camera className="w-5 h-5" />
                                        </button>
                                    </div>
                                    <div className="flex-grow space-y-4 w-full  md:w-auto">
                                        <div>
                                            <label htmlFor="full-name" className="block text-gray-700 dark:text-gray-200 mb-2">Full Name</label>
                                            <input id="full-name" type="text" placeholder="Full name" className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]" />
                                        </div>
                                        <div>
                                            <label htmlFor="username" className="block text-gray-700 dark:text-gray-200 mb-2">Username</label>
                                            <input id="username" type="text" placeholder="Username" className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]" />
                                        </div>
                                        <div>
                                            <label htmlFor="biography" className="block text-gray-700 dark:text-gray-200 mb-2">Biography</label>
                                            <textarea id="biography" placeholder="Biography" className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]" rows="4"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-white">Private Information</h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor="unique-id" className="block text-gray-700 dark:text-white mb-2">ID</label>
                                        <input id="unique-id" type="text" placeholder="Unique ID" className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark dark:bg-[rgba(1,14,78,0.96)]" readOnly />
                                    </div>
                                    <div>
                                        <label htmlFor="select-option" className="block text-gray-700 dark:text-white mb-2">Role</label>
                                        <select id="select-option" className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]">
                                            <option>Admin</option>
                                            <option>User</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block text-gray-700 dark:text-gray-200 mb-2">Email</label>
                                        <input id="email" type="email" placeholder="Email" className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]" />
                                    </div>
                                    <div>
                                        <label htmlFor="phone" className="block text-gray-700 dark:text-gray-200 mb-2">Phone</label>
                                        <input id="phone" type="tel" placeholder="Phone" className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]" />
                                    </div>
                                    <div>
                                        <label htmlFor="website" className="block text-gray-700 dark:text-gray-200 mb-2">Website</label>
                                        <input id="website" type="url" placeholder="Website" className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]" />
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="address" className="block text-gray-700 dark:text-gray-200 mb-2">Address</label>
                                    <textarea id="address" placeholder="Address" className="w-full p-3 border rounded-custom shadow-custom-light dark:shadow-custom-dark  dark:bg-[rgba(1,14,78,0.96)]" rows="3"></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="px-6 py-4 bg-gray-50 flex justify-end">
                            <button className="flex items-center space-x-2 bg-customPurple text-white px-6 py-2 rounded-custom  transition duration-300">
                                <Save className="w-5 h-5" />
                                <span>Save Changes</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default UserProfileEdit;
