// import React from 'react';
// import { Link, Outlet } from 'react-router-dom';

// export default function ProtectedRoute() {
//   const token = localStorage.getItem('authToken');

//   return token ? <Outlet/>: <Link to="/login" />;
// }


import { useAuth } from './Auth';
import { Outlet, Navigate } from 'react-router-dom';

const Protectedroute = () => {
  const { isAuthenticated, loading } = useAuth();
  if (loading) {
    return <div>Loading...</div>; 
  }
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default Protectedroute;
