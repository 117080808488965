import React, { useState } from 'react';
import { Button, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../../../context/MyContext';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import '../../../css/custom-scrollbar.css';
import { LuWarehouse } from "react-icons/lu";

const RateCard = () => {
  const { isDarkMode } = useMyContext();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { isToggleSidebar } = useMyContext();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const navigate = useNavigate();

  // Static warehouse data
  const warehouses = [
    {  courierName: 'FedEx', withinCity: '₹50', withinState: '₹80', metro: '₹100', restOfIndia: '₹150', northEast: '₹200', codCharges: '₹30' },
    {  courierName: 'DHL', withinCity: '₹60', withinState: '₹85', metro: '₹105', restOfIndia: '₹155', northEast: '₹210', codCharges: '₹35' },
    { courierName: 'Blue Dart', withinCity: '₹55', withinState: '₹90', metro: '₹110', restOfIndia: '₹160', northEast: '₹220', codCharges: '₹40' },
    { courierName: 'Delhivery', withinCity: '₹45', withinState: '₹75', metro: '₹95', restOfIndia: '₹145', northEast: '₹195', codCharges: '₹25' },
    {  courierName: 'Aramex', withinCity: '₹65', withinState: '₹95', metro: '₹115', restOfIndia: '₹170', northEast: '₹225', codCharges: '₹45' },
    {courierName: 'Gati', withinCity: '₹70', withinState: '₹100', metro: '₹120', restOfIndia: '₹180', northEast: '₹230', codCharges: '₹50' },
    {  courierName: 'Ecom Express', withinCity: '₹40', withinState: '₹70', metro: '₹90', restOfIndia: '₹140', northEast: '₹190', codCharges: '₹20' },
    {  courierName: 'XpressBees', withinCity: '₹75', withinState: '₹105', metro: '₹125', restOfIndia: '₹185', northEast: '₹240', codCharges: '₹55' },
    {  courierName: 'Shadowfax', withinCity: '₹80', withinState: '₹110', metro: '₹130', restOfIndia: '₹190', northEast: '₹250', codCharges: '₹60' },
    {  courierName: 'Wow Express', withinCity: '₹85', withinState: '₹115', metro: '₹135', restOfIndia: '₹195', northEast: '₹260', codCharges: '₹65' }
  ];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = warehouses.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleNavigation = () => {
    navigate('/dashboard');
  };

  return (
    <>
      <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
        <div className="z-10">
          <NavBar />
        </div>

        <div className="flex flex-1 overflow-hidden">
          {isToggleSidebar && (
            <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768 
                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>
              <Sidebar />
            </div>
          )}

          <div className={`shadow-custom-light ml-3 mt-6 mb-6 mr-6 w-full rounded-custom  custom-scrollbar overflow-y-auto dark:shadow-custom-dark p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : ''}`}>

            <button onClick={handleNavigation}
              className="text-xl w-full sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] sm:w-[49%] flex items-center justify-center">
              {/* <LuWarehouse className="mr-2" size={24} /> */}
              Rate Card
            </button>

            {/* Express and Surface Buttons */}
            <div className="flex gap-4 mb-6 justify-center">
              <button   className="text-xl w-full sm:text-xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] sm:w-full flex items-center justify-center">
                Express
              </button>
              <button className="text-xl w-full sm:text-xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] sm:w-full flex items-center justify-center">
                Surface
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full w-full table-auto">
                <thead className="bg-[#0540a6dd] text-white text-[14px]">
                  <tr>
                    <th className="p-2 text-left w-1/12">Courier Name</th>
                    <th className="p-2 text-left w-2/12">Within City</th>
                    <th className="p-2 text-left w-3/12">Within State</th>
                    <th className="p-2 text-left w-2/12">Metro</th>
                    <th className="p-2 text-left w-2/12">Rest of India</th>
                    <th className="p-2 text-left w-2/12">J&K, NorthEast</th>
                    <th className="p-2 text-left w-2/12">COD Charges</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((warehouse) => (
                    <tr key={warehouse.courierName}>
                      <td className="p-2">{warehouse.courierName}</td>
                      <td className="p-2">{warehouse.withinCity}</td>
                      <td className="p-2">{warehouse.withinState}</td>
                      <td className="p-2">{warehouse.metro}</td>
                      <td className="p-2">{warehouse.restOfIndia}</td>
                      <td className="p-2">{warehouse.northEast}</td>
                      <td className="p-2">{warehouse.codCharges}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-between items-center mt-4">
              <p>Showing <b>{indexOfFirstItem + 1}</b> to <b>{Math.min(indexOfLastItem, warehouses.length)}</b> of <b>{warehouses.length}</b> results</p>
              <Pagination
                count={Math.ceil(warehouses.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  '.MuiPaginationItem-text': {
                    color: isDarkMode ? 'white' : 'defaultColor', 
                  },
                }}
                color="primary"
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RateCard;
