// NavBar.js
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdMenuOpen, MdOutlineMenu } from 'react-icons/md';
import { CiLight } from 'react-icons/ci';
import { FaWallet, FaBell, FaMoon } from 'react-icons/fa';
import { CgSearch } from "react-icons/cg";
import { useMyContext } from '../../context/MyContext';
import { toast } from 'react-hot-toast'
import { useAuth } from '../../routes/Auth';
const NavBar = () => {
  const { setIsToggleSidebar, isToggleSidebar, isDarkMode, toggleTheme,setorderId } = useMyContext();
  const navigate = useNavigate();
  const [walletMenuVisible, setWalletMenuVisible] = useState(false);
  const walletMenuRef = useRef(null);
  const userMenuRef = useRef(null);
  const [userName, setUserName] = useState("");
  const [userMenu, setUserMenu] = useState(false);
  const { logout } = useAuth()
  const [orderIdInput, setOrderIdInput] = useState('');
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser && storedUser.userData.name) {
      setUserName(storedUser.userData.name);
    }
  }, []);

  const toggleWalletMenu = () => {
    setWalletMenuVisible(!walletMenuVisible);
  };

  const toggleUserMenu = () => {
    setUserMenu(!userMenu);
  }
  const goToTopUp = () => {
    navigate('/payments/walletbalance');
  };

  const navigation = () => {
    navigate('/profile');
  }

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate('/login')
    logout()
    localStorage.removeItem('cus_id')
    localStorage.removeItem('token')
    toast.success("LogOut Sucessfully!")
  }

  const goToHistory = () => {
    navigate('/payments/history');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (walletMenuRef.current && !walletMenuRef.current.contains(event.target)) {
        setWalletMenuVisible(false);
      }
      else if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setUserMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [walletMenuRef, userMenuRef]);

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (orderIdInput.trim()) { 
        setorderId(orderIdInput);
        navigate('/shipments/tracking');
      } else {
        console.log('Please enter a valid Order ID.');
      }
    }
  };
  

  return (
    <div className="flex flex-wrap items-center justify-between px-4 py-2  md:py-0 relative ">
      <div className="flex items-center">

        <Link to="/dashboard">
          <div className="flex items-center cursor-pointer pl-0 md:pl-0">
            <img src="/assets/images/logo1.png" alt="Logo" className="w-[60px] h-[40px] sm:w-[120px] sm:h-[50px] lg:w-[190px] lg:h-[100px]" />
          </div>
        </Link>
        <button
          onClick={() => setIsToggleSidebar(!isToggleSidebar)}
          className="ml-7 md:ml-10 text-blue-500 dark:text-blue-400"
        >
          {isToggleSidebar === false ? <MdMenuOpen size={26} /> : <MdOutlineMenu size={26} />}
        </button>
      </div>

      <div className="flex items-center space-x-2 md:space-x-4  lg:pr-24  pr-0 order-2 md:order-3 relative">
        <button onClick={toggleTheme} className="text-blue-500 dark:text-blue-400 pr-4">
          {isDarkMode ? <CiLight size={24} className="text-white" /> : <FaMoon size={24} />}

        </button> 
         <button 
      onClick={toggleWalletMenu} 
      className="text-blue-500 dark:text-blue-400 pr-4 relative focus:outline-none rounded-full"
      aria-label="Toggle wallet menu"
    >
      <FaWallet size={24} className={`transition-colors duration-200 ${isDarkMode ? "text-white" : ""}`} />
      {walletMenuVisible && (
        <div
          ref={walletMenuRef}
          className="absolute right-0 mt-4 w-56 bg-white dark:bg-[#071a74f6] border border-gray-200 rounded-lg shadow-lg z-50 transition-all duration-200 ease-in-out overflow-hidden"
        >
          <ul className="text-gray-700 dark:text-gray-200 dark:shadow-custom-dark divide-y divide-gray-200 dark:divide-gray-700">
            <li
              className="py-2 px-4 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 transition-colors duration-200 flex items-center justify-between"
              onClick={goToHistory}
            >
              <span>Payments History</span>
              <span className="text-gray-400">|</span>
            </li>
            <li
              className="py-2 px-4 cursor-default flex items-center justify-between"
            >
              <span>Balance</span>
              <span className="font-semibold">₹ 4000</span>
            </li>
            <li
              className="py-2 px-4 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 transition-colors duration-200 flex items-center justify-between"
              onClick={goToTopUp}
            >
              <span>Top-Up</span>
              <span className="text-gray-400">|</span>
            </li>
          </ul>
        </div>
      )}
    </button>
        <button className="text-blue-500 dark:text-blue-400 pr-4">
          <FaBell size={24} className={isDarkMode ? "text-white" : ""} />
        </button>
        <button
  onClick={toggleUserMenu}
  className={`text-white hidden lg:flex rounded-md dark:text-white pr-4 items-center relative`}
>
  <div className="flex items-center space-x-3 md:space-x-5">
    <div className="relative group">
      <img
        src="/assets/images/man.png"
        alt="Profile picture"
        className={`w-8 h-10 md:w-8 md:h-8 rounded-full border-2 shadow-lg transform transition-transform duration-300 group-hover:scale-105 z-10 ${
          isDarkMode ? "border-white" : "border-gray-900"
        } `}
      />
      {/* Optional online status indicator */}
      <span className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full md:w-3.5 md:h-3.5"></span>
    </div>
    <span
      className={`text-lg md:text-md font-semibold transition-colors duration-300 ${
        isDarkMode ? "text-white glow" : "text-gray-900"
      }`}
    >
      {userName}
    </span>
  </div>

  {userMenu && (
    <div
      ref={userMenuRef}
      className="absolute top-full mt-4 w-28 bg-white dark:bg-[#071a74f6] border border-gray-200 rounded-lg shadow-lg z-50 transition-all duration-200 ease-in-out overflow-hidden"
    >
      <ul className="text-gray-700 dark:text-gray-200 dark:shadow-custom-dark divide-y divide-gray-200 dark:divide-gray-700">
        <li
          onClick={navigation}
          className="py-2 px-4 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 transition-colors duration-200 flex items-center justify-between"
        >
          Profile
        </li>
        <li
          onClick={handleLogout}
          className="py-2 px-4 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 transition-colors duration-200 flex items-center justify-between"
        >
          Logout
        </li>
      </ul>
    </div>
  )}
</button>

      </div>
      <div className="flex-grow mx-2 md:mx-14 lg:pl-20 order-3 md:order-2 w-full md:w-auto mt-4 md:mt-0">
        <div className="relative flex items-center bg-gray-100 dark:bg-[#071a74f6] rounded-md w-full md:w-[90%]">
          <CgSearch className={`absolute left-3 ${isDarkMode ? "dark:text-white" : "text-custom-gray"}`} size={24} />
          <input
            type="text"
            value={orderIdInput}
            onChange={(e) => setOrderIdInput(e.target.value)}
            onKeyDown={handleSearchKeyDown}
            placeholder="Search with LRN/AWB/OID"
            className={`font-sans bg-gray-200 dark:bg-[#071a74f6] w-full h-[40px] border-0 outline-none rounded-[10px] pl-[50px] pr-[10px] bg-opacity-70 text-[12px] md:text-[22px] ${isDarkMode ? "placeholder-white" : "placeholder-custom-gray"} font-san dark:text-white`}
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
