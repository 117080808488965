import React, { useState,useEffect,useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { RxDashboard } from "react-icons/rx";
import { FaAngleRight,FaAngleDown } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";
import { CiCircleAlert, CiBank, CiSettings } from "react-icons/ci";
import { LuWarehouse } from "react-icons/lu";
import { PiAsteriskSimpleThin } from "react-icons/pi";
import { TbReport } from "react-icons/tb";
import { GoMilestone } from "react-icons/go";
import { MdSupportAgent } from "react-icons/md";
import { MdGroups2 } from "react-icons/md";
import { RiGroupLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { useMyContext } from "../../context/MyContext";
import '../css/custom-scrollbar.css';
import { Toaster,toast } from "react-hot-toast";
import { useAuth } from "../../routes/Auth";
import { LogOut } from 'lucide-react';
import { Lock } from 'lucide-react';

const Sidebar = () => {
  const [activeTab, setActiveTab] = useState(null);
  const { isToggleSidebar,setIsToggleSidebar,isDarkMode } = useMyContext();
  const navigate = useNavigate();
  const { logout } = useAuth()
  const sidebarRef = useRef(null)

  const toggleSubmenu = (index, href) => {
    if (href) {
      navigate(href);
    } else {
      setActiveTab(activeTab === index ? null : index);
    }
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsToggleSidebar(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        window.innerWidth <= 768 
      ) {
        setIsToggleSidebar(false); 
      }
    };

    if (isToggleSidebar) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isToggleSidebar, setIsToggleSidebar]);
  
  const menuItems = [
    {
      title: "Dashboard",
      icon: <RxDashboard />,
      href: "/dashboard",
      submenu: [],
    },
    {
      title: "Shipments",
      icon: <IoCreateOutline />,
      submenu: [
        { title: "Forward Shipment", href: "/shipments/forward" },
        { title: "Reverse Shipment", href: "/shipments/reverse" },
        { title: "Track Shipments", href: "/shipments/tracking" },
        { title: "Pickup Request", href: "/shipments/pickuprequest" },
        { title: "View Shipments", href: "/shipments/all" },
      ],
    },
    {
      title: "Warehouse",
      icon: <LuWarehouse />,
      submenu: [
        { title: "Add Warehouse", href: "/warehouse/add" },
        { title: "All Warehouses", href: "/warehouse/all" },
      ],
    },
    {
      title: "Disputes",
      icon: <CiCircleAlert />,
      submenu: [
        { title: "NDR", href: "/disputes/NDR" },
        { title: "RTO", href: "/disputes/RTO" },
        { title: "Weight Disputes", href: "/disputes/weight" },

      ],
    },
    {
      title: "Payments",
      icon: <CiBank />,
      submenu: [
        { title: "Remmitance", href: "/payments/remmitance" },
        { title: "Invoice", href: "/payments/invoice" },
        { title: "Wallet Balance", href: "/payments/walletbalance" },
        { title: "Payments History", href: "/payments/history" },
      ],
    },
    {
      title: "Risk",
      icon: <PiAsteriskSimpleThin />,
      submenu: [
        // { title: "Claims", href: "/risk/claims/lost&damages" },
      ],
    },
    {
      title: "Services",
      icon: <GoMilestone />,
      submenu: [],
    },
    // {
    //   title: "Employee",
    //   icon: <MdGroups2 />,
    //   submenu: [
    //     // { title: "Add Employee", href: "/employee/add" },
    //     // { title: "Add Department", href: "/employee/settings" },
    //   ],
    // },
    {
      title: "Customer",
      icon: <RiGroupLine />,
      submenu: [
        // { title: "UpdateKYC", href: "/customers/updateKYC" },
        // { title: "Add customer", href: "/customers/addCustomer" },
      ],
    },
    {
      title: "Settings",
      icon: <CiSettings />,
      submenu: [
        { title: "Rate Calculator", href: "/settings/rateCalculator" },
        // { title: "Servicable Pincodes", href: "/settings/pincode" },
        // { title: "Rate Card", href: "/settings/rateCard" },
        // { title: "Zone", href: "/settings/account" },
        // { title: "Add Pincodes", href: "/settings/addPincode" },
        // { title: "Set Rate", href: "/settings/setRate" },
      ],
    },
    {
      title: "Reports" ,
      // href: "/reports",
      icon: <TbReport />,
      submenu: [],
    },
    {
      title: "Help",
      icon: <MdSupportAgent />,
      submenu: [],
    },
    {
      title: "Profile",
      icon: <CgProfile />,
      href: "/profile",
      submenu: [],
    },
  ];


const handleLogout = () => {
  localStorage.removeItem("user");
  navigate('/login')
  logout()
  localStorage.removeItem('cus_id')
  localStorage.removeItem('token')
  toast.success("LogOut Sucessfully!")
}


  return (
    <>
      {isToggleSidebar && (
        <div ref={sidebarRef} className="w-64 h-full flex flex-col  transform transition-transform duration-300 pl-2 custom-scrollbar ">
          <ul className="flex-1">
            {menuItems.map((item, index) => (
              <li key={index}>
                <Button
                  sx={{
                    fontFamily: 'var(--font-custom-open-sans)',
                    textTransform: 'none',
                    color: isDarkMode ? 'white' : 'rgba(6, 6, 6, 0.616)',
                    width: '100%',
                    textAlign: 'left',
                    fontWeight:'bold',
                    display: 'flex',
                    // paddingLeft:'1.5rem',
                    alignItems: 'center',
                    padding: '0.75rem 1rem',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      backgroundColor: 'rgba(128,128,128, 0.3)',
                      color: isDarkMode ? 'white' : 'rgba(6, 6, 6, 0.616)',
                      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={() => toggleSubmenu(index, item.href)}
                >
                  <span className="icon mr-2 text-xl text-custom-green">
                    {item.icon}
                  </span>
                  <span className="pl-3">
                  {item.title}
                  </span>
                  <span
                className="arrow ml-auto"
              >
                {activeTab === index ? <FaAngleDown /> : <FaAngleRight />}
              </span>
                </Button>
                {item.submenu.length > 0 && activeTab === index && (
                  <div className="submenuWrapper pl-6">
                    <ul className="submenu text-custom-gray border-l pl-4 border-gray-600">
                      {item.submenu.map((subItem, subIndex) => (
                        <li
                          key={subIndex}
                          className={`py-1 px-1 dark:text-white ${
                            activeTab === index
                              ? "text-gray-700 font-bold dark:text-customPurple"
                              : ""
                          }`}
                        >
                          <Link
                            to={subItem.href}
                            className="text-sm font-semibold hover:opacity-70 hover:text-customPurple transition-opacity duration-300"
                            aria-label={subItem.title}
                            style={{
                              fontFamily: 'var(--font-custom-open-sans)',
                              color: isDarkMode ? 'white' : 'rgba(6, 6, 6, 0.616)',
                            }}
                          >
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
          {/* <span className="mt-12 mb-12 ml-10 w-32 font-sans">
          <Button onClick={handleLogout}  sx={{ textTransform: 'capitalize', 
            color : isDarkMode ? 'white' : 'white',
            backgroundColor:"#3B82F6",
            marginLeft:"42px",
            }} 
            className=' text-white bg-blue-500 hover:text-white hover:bg-blue-600 w-32 font-sans h-8 '>
          LogOut
        </Button>
        </span> */}
      <div className="bg-blue-200 p-6 rounded-lg shadow-md  md:mt-8 md:mb-10 mt-8 mb-10 relative">
      {/* <div className="absolute top-6 left-7 w-8 h-8  bg-blue-300 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute  right-4 bottom-16 w-12 h-12   bg-blue-200 rounded-full translate-x-1/3 translate-y-1/3"></div> */}
      <button
       onClick={handleLogout}
        className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-sans font-medium py-2 px-4 ml-7 rounded-md transition duration-300 ease-in-out w-28"
      >
        <LogOut className="mr-2 h-4 w-4" />
        LogOut
      </button>
    </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
