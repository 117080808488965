import React from 'react';
import { Upload } from 'lucide-react';

const AddCustomer = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 bg-indigo-600 text-white py-2 px-4 rounded-md inline-block">
        Onboard A New Customer
      </h2>
      
      <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div>
            <label htmlFor="customerName" className="block text-sm font-medium text-gray-700">Customer Name</label>
            <input type="text" id="customerName" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
          </div>
          
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input type="email" id="email" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
          </div>
          
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
            <input type="tel" id="phone" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
          </div>
          
          <div>
            <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Company Name</label>
            <input type="text" id="companyName" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
          </div>
          
          <div>
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
            <textarea id="address" rows={3} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"></textarea>
          </div>
          
          <div>
            <h3 className="text-lg font-medium text-gray-700 mb-2">Bank Details</h3>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label htmlFor="bankName" className="block text-sm font-medium text-gray-700">Bank Name</label>
                <input type="text" id="bankName" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
              </div>
              <div>
                <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700">Account Number</label>
                <input type="text" id="accountNumber" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
              </div>
              <div>
                <label htmlFor="ifscCode" className="block text-sm font-medium text-gray-700">IFSC Code</label>
                <input type="text" id="ifscCode" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
              </div>
            </div>
          </div>
          
          <div>
            <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <Upload className="mr-2 h-5 w-5" />
              Upload Cancel Cheque
            </button>
          </div>
        </div>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="companyType" className="block text-sm font-medium text-gray-700">Company Type</label>
            <select id="companyType" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              <option>Select Company Type</option>
            </select>
          </div>
          
          <div>
            <label htmlFor="gstin" className="block text-sm font-medium text-gray-700">GSTIN</label>
            <input type="text" id="gstin" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
          </div>
          
          <div>
            <label htmlFor="requiredDocument" className="block text-sm font-medium text-gray-700">Required Document</label>
            <select id="requiredDocument" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              <option>Select Valid Document</option>
            </select>
          </div>
          
          <div>
            <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <Upload className="mr-2 h-5 w-5" />
              Upload file
            </button>
          </div>
          
          <div>
            <label htmlFor="kycNumber" className="block text-sm font-medium text-gray-700">KYC Number</label>
            <input type="text" id="kycNumber" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Select Docs</label>
            <div className="mt-2 space-x-4">
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio text-indigo-600" name="docs" value="pan" />
                <span className="ml-2">Pan</span>
              </label>
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio text-indigo-600" name="docs" value="aadhar" />
                <span className="ml-2">Aadhar</span>
              </label>
            </div>
          </div>
          
          <div>
            <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <Upload className="mr-2 h-5 w-5" />
              Upload file
            </button>
          </div>
          
          <div>
            <button type="submit" className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              SUBMIT
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCustomer;