import React from 'react';
// import { BrowserRouter as Router,, Route } from 'react-router-dom';
// import Dashboard from '../src/pages/dashborad/Dashboard';
// import ReverseShipment from './pages/shipment/reverse/reverseShipment';
// import ForwordShipment from './pages/shipment/forword/forwordShipment';
// import PickupRequest from './pages/shipment/pickuprequest/pickupRequuest';
// import ShipmentTracking from './pages/shipment/tracking/shipmentTracking';
// import AddWarehouse from './pages/warehouse/addwarehouse/addWarehouse';
// import AllWarehouses from './pages/warehouse/allWarehouse/allwarehouse';
// import NDRDashboard from './pages/disputes/NDR/ndr';
// import RTODashboard from './pages/disputes/RTO/rto';
// import WeightDisputeDashboard from './pages/disputes/weightdisputes/weightDisputes';
// import PageRemittances from './pages/payments/remmitance/Remmitance';
// import PageInvoices from './pages/payments/invoice/Invoice';
// import WalletRecharge from './pages/payments/walletReachrge/Walletrecharge';
// import PaymentHistory from './pages/payments/payment history/Paymenthistory';
// import ShippingCharges from './pages/payments/shippingCharges/ShippingCharges';
// import TopupHistory from './pages/payments/topuphistory/topupHistory';
// import LostDamageClaims from './pages/risk/LostandDamages';
// import ReportsDropdown from './pages/reports/Reports';

import AllRoutes from './routes/Routes';
function App() {
  return (
   <>
    <AllRoutes/>
   </>
  );
}

export default App;
