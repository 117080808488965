"use client";

import React, { useState } from 'react';
import { Button, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../../context/MyContext';
import { MdDashboard } from 'react-icons/md';
import NavBar from '../../component/navBar/navBar';
import Sidebar from '../../component/sidebar/sidebar';

export default function LostDamageClaims() {
    const [activeTab, setActiveTab] = useState('Open');
    const { isDarkMode, isToggleSidebar } = useMyContext();
    const navigate = useNavigate();

    const tabs = ['Open', 'Approved', 'Rejected', 'CN Generated', 'All'];

    const renderTableHeaders = () => {
        const commonHeaders = ['Claim ID', 'AWB No.', 'Customer Info', 'Value', 'Last Updated', 'Remark'];
        if (activeTab === 'Open') {
            return [...commonHeaders, 'Action'];
        }
        return commonHeaders;
    };



    const renderTableRow = (index) => {
        const commonCells = [
            <td key="claimId" className='p-2 text-left w-[12%]'>091234567</td>,
            <td key="awbNo" className='p-2 text-left w-[12%]'>AWB123456</td>,
            <td key="customerInfo" className='p-2 text-left w-[18%]'>John Doe</td>,
            <td key="value" className='p-2 text-left w-[12%]'>₹ 2000</td>,
            <td key="lastUpdated" className='p-2 text-left w-[18%]'>2023-08-23</td>,
            <td key="remark" className='p-2 text-left w-[18%]'>Pending</td>
        ];

        if (activeTab === 'Open') {
            commonCells.push(
                <td key="action" className='p-2 w-[18%]'>
                    <div className='flex space-x-2'>
                        <Button className='capitalize' sx={{ textTransform: "capitalize" }} variant="contained" size="small" color="primary">Approve</Button>
                        <Button className='capitalize' sx={{ textTransform: "capitalize" }} variant="contained" size="small" color="secondary">Reject</Button>
                    </div>
                </td>
            );
        }

        return (
            <tr key={index} className='border-b'>
                {commonCells}
            </tr>
        );
    };

    const handleTabClick = (tab) => {
        if (tab === 'CN Generated') {
            navigate('/claims/CNGenerated');
        }
        setActiveTab(tab);
    };
   
    const handleNavigate = () =>{
        navigate("/dashboard")
    }

    return (

        <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
            <div className="z-10">
                <NavBar />
            </div>

            <div className="flex flex-1 overflow-hidden">
                {isToggleSidebar && (
                    <div
                        className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
                        style={
                            window.innerWidth < 768 
                              ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                              : {}
                          }  >  
                        <Sidebar />
                    </div>
                )}
   <div className="dark:bg-[rgba(1,14,78,0.96)] w-full dark:text-white shadow-custom-light dark:shadow-none rounded-custom mt-4 p-4 md:ml-4 md:mt-4 md:mb-8 md:mr-4 custom-scrollbar overflow-y-auto">
                    <div className="container mx-auto p-4">
                        <button onClick={handleNavigate} 
                         className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-600 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full flex items-center justify-center">
                            <MdDashboard className="mr-2" size={24} />
                            Lost/Damage Claims 
                        </button>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 w-full">
                            {tabs.map((tab) => (
                                <button
                                    key={tab}
                                    className={`px-4 py-2 md:mr-12 rounded ${activeTab === tab ? ' text-white dark:bg-customPurple bg-customPurple hover:bg-purple-700 dark:hover:bg-purple-700' : 'dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark shadow-custom-light rounded-custom'
                                        }`}
                                    onClick={() => handleTabClick(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>

                        <div className='rounded-custom shadow-custom-light dark:shadow-none p-4'>
                            <div className='overflow-x-auto'>
                                <table className='min-w-full table-auto dark:text-white dark:bg-[rgba(1,14,78,0.96)]'>
                                    <thead className='bg-[#0540a6dd] text-white text-[14px]'>
                                        <tr>
                                            {renderTableHeaders().map((header, index) => (
                                                <th key={index} className='p-2 text-left'>{header}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...Array(7)].map((_, index) => renderTableRow(index))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='flex justify-between items-center mt-4 dark:text-white'>
                                <p>Showing <b>1</b> of <b>50</b> results</p>
                                <Pagination 
                                 sx={{
                                    '.MuiPaginationItem-text': {
                                      color: isDarkMode ? 'white' : 'defaultColor', 
                                    },
                                    '.MuiPaginationItem-root': {
                                    },
                                  }}
                                 count={10} color="primary" size="small" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
