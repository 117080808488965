import React, { useState } from 'react';
import { Search, MapPin } from 'lucide-react';
import { useMyContext } from '../../../context/MyContext';
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';

const PincodeService = () => {
  const [pincode, setPincode] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const { isDarkMode, isToggleSidebar } = useMyContext();

  const handleCheck = () => {
    setIsChecking(true);
    // Simulate API call
    setTimeout(() => {
      setIsChecking(false);
      // Handle the result here
    }, 1500);
  };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768
                ? { top: '113px', height: 'calc(100vh - 115px)', overflowY: 'auto' }
                : {}
            }
          >
            <Sidebar />
          </div>
        )}
         <div className='shadow-custom-light dark:shadow-custom-dark rounded-custom w-full mt-3 mb-3 mr-3 ml-4'>
        <div className={`p-4 md:p-6 min-h-[50vh] custom-scrollbar overflow-y-auto dark:bg-[rgba(1,14,78,0.96)] ${isToggleSidebar ? 'ml-0' : 'mr-0'} ${isToggleSidebar ? 'w-full' : 'w-full'} mb-8 md:mb-0 `}>
          <div className="max-w-4xl mx-auto">
            <div className="bg-white dark:bg-[rgba(1,14,78,0.96)]   dark:shadow-custom-dark shadow-custom-light rounded-custom overflow-hidden">
              <div className=" bg-customPurple p-6 text-white rounded-custom shadow-custom-light dark:shadow-custom-dark">
                <h2 className="text-2xl font-bold">Pincode Service</h2>
                <p className="text-purple-200">Check delivery availability for your area</p>
              </div>

              <div className="p-8">
                <div className="mb-8">
                  <label htmlFor="pincode" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                    Enter Pincode
                  </label>
                  <div className="relative rounded-md shadow-lg">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <MapPin className="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      type="text"
                      name="pincode"
                      id="pincode"
                      className={`block w-full pl-10 pr-12 py-3 shadow-custom-light dark:shadow-custom-dark rounded-custom border-gray-300  text-lg dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:placeholder-white  placeholder-custom-gray`}
                      placeholder="e.g., 123456"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center">
                      <button
                        type="button"
                        onClick={handleCheck}
                        disabled={isChecking || pincode.length !== 6}
                        className={`inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white transition-all duration-300 ease-in-out ${isChecking || pincode.length !== 6
                          ? 'bg-gray-300 dark:bg-gray-600 cursor-not-allowed'
                          : 'bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                          }`}
                      >
                        {isChecking ? (
                          <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                          </svg>
                        ) : (
                          <Search className="h-5 w-5 mr-2" />
                        )}
                        {isChecking ? 'Checking...' : 'Check'}
                      </button>
                    </div>
                  </div>
                </div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Enter a 6-digit pincode to check service availability in your area.
                </p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default PincodeService;
