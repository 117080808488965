import { Dialog, DialogTitle, DialogContent, Radio, Button } from '@mui/material';
import React, { useState } from 'react';
import { useMyContext } from '../../context/MyContext';
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { IoIosCloseCircle } from "react-icons/io";
import Spinner from '../spinner/spinner';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Base_Url,reverseShipment,createShippment } from '../../config/config';

const ViewRateForword = ({ isopen, onClose, data, reqBody, type }) => {
  const { isDarkMode } = useMyContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const userData = JSON.parse(localStorage.getItem('user')) || {};

  const handleNavigation = () => {
    navigate('/dashboard');
  };

  const tableData = data && data.data && data.data.partners_pricing ? [
    ...(data.data.partners_pricing.Delhivery ? Object.entries(data.data.partners_pricing.Delhivery).map(([mode, pricing], index) => ({
      id: `Delhivery-${mode}-${index}`,
      courier: "delhivery",
      mode,
      zone: data.data.zone,
      basePrice: pricing.total_price,
      codExtra: pricing.cod_extra,
      codTotalPrice: pricing.cod_total_price,
      shippingMode: mode
    })) : []),
    ...(data.data.partners_pricing.Shiprocket ? Object.entries(data.data.partners_pricing.Shiprocket).map(([mode, pricing], index) => ({
      id: `Shiprocket-${mode}-${index}`,
      courier: "shiprocket",
      mode,
      zone: data.data.zone,
      basePrice: pricing.total_price,
      codExtra: pricing.cod_extra,
      codTotalPrice: pricing.cod_total_price,
      shippingMode: mode
    })) : [])
  ] : [];

  const handleRadioChange = (event, row) => {
    setSelectedRowId(row.id);
    setSelectedRowData(row);
  };

  const handleCreateShipment = async () => {
    const token = localStorage.getItem('token');
    const cusid = localStorage.getItem('cus_id');

    if (selectedRowData) {
      const requestBody = { ...reqBody };
      delete requestBody.from_pincode;
      requestBody.pin = requestBody.to_pincode;
      delete requestBody.to_pincode;
      requestBody.shipping_mode = selectedRowData.shippingMode;
      requestBody.partner = selectedRowData.courier;
      requestBody.product_desc = "product_desc";

      setIsSubmitting(true);
  

      try {
        // const apiUrl = type === "reverse" 
        //   ? "http://192.168.1.36/backendci4/api/reverseShipment"
        //   : "http://192.168.1.36/backendci4/api/createShippment";
         
        const apiUrl = type === "reverse"
        // ? "http://192.168.1.36/backendci4/api/reverseShipment" 
        ? Base_Url + reverseShipment
        : Base_Url + createShippment; 
    const response = await axios.post(apiUrl, requestBody, {
        headers: {
            token,
            cus_id: cusid,
            'Content-Type': 'application/json',
        },
    });

        if (response.data.status === "SUCCESS") {
          toast.success(response.data.message);
          handleClose();
          setIsSubmitting(false);
          handleNavigation();
        } else {
          toast.error(response.data.message);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("API Error:", error);
        toast.error(error.response?.data?.message || "An error occurred");
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={isopen}
      onClose={onClose}
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          height: '80vh',
        }
      }}
    >
      <DialogTitle className="bg-customPurple text-white text-center">
        <h2 className="text-2xl font-semibold flex items-center justify-center">
          <RiMoneyRupeeCircleFill />
          Rate Calculator
        </h2>
        <IoIosCloseCircle
          className="absolute top-2 right-2 cursor-pointer"
          size={32}
          onClick={handleClose}
        />
      </DialogTitle>

      <DialogContent
        className={`shadow-custom-light rounded-custom dark:shadow-custom-dark mt-4 ml-2 mr-2 mb-4 p-4 
        ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white" : ""}`}
        sx={{ overflowY: 'auto' }}
      >
        <div className="overflow-x-auto md:mt-8 md:mb-8 md:ml-8 md:mr-8">
          <table border={1} className="min-w-full mt-3 md:mt-5 table-auto">
            <thead className="bg-[#0540a6dd] text-white text-[12px]">
              <tr>
                <th className="p-2 text-left">Select</th>
                <th className="p-2 text-left">Courier Name</th>
                <th className="p-2 text-left">Mode</th>
                <th className="p-2 text-left">Zone</th>
                <th className="p-2 text-left">Forward Price</th>
                <th className="p-2 text-left">COD Price</th>
                <th className="p-2 text-left">Total Price</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? tableData.map((row) => (
                <tr key={row.id} className="border-b">
                  <td className="p-2">
                    <Radio
                      checked={selectedRowId === row.id}
                      onChange={(event) => handleRadioChange(event, row)}
                      value={row.id}
                      name="selectRow"
                      inputProps={{ 'aria-label': 'select row' }}
                    />
                  </td>
                  <td className="p-2">{row.courier && row.courier.toLowerCase()}</td>
                  <td className="p-2">{row.mode || "N/A"}</td>
                  <td className="p-2">{row.zone || "N/A"}</td>
                  <td className="p-2">{row.basePrice ? `₹ ${row.basePrice}` : "N/A"}</td>
                  <td className="p-2">{row.codExtra ? `₹ ${row.codExtra}` : "N/A"}</td>
                  <td className="p-2">{row.codTotalPrice ? `₹ ${row.codTotalPrice}` : "N/A"}</td>

                </tr>
              )) : (
                <tr>
                  <td className="p-2 text-center" colSpan="7">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </DialogContent>

      <div className="flex justify-end">
        <button
          className="text-lg md:texl-sm w-[80%] md:w-[20%] font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 dark:bg-customPurple dark:hover:bg-purple-700 text-white font-sans rounded h-[40px] flex items-center justify-center mr-3"
          onClick={handleCreateShipment}
          disabled={!selectedRowId || isSubmitting}
        >
          {/* {isSubmitting ? <Spinner /> : "Create Shipment"} */}
          {isSubmitting ? <Spinner /> : (type === "reverse" ? "Reverse Shipment" : "Create Shipment")}
        </button>
      </div>
    </Dialog>
  );
};

export default ViewRateForword;
