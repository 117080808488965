import React from 'react';
import { Upload } from 'lucide-react';
import NavBar from '../../component/navBar/navBar';
import Sidebar from '../../component/sidebar/sidebar';
import { useMyContext } from '../../context/MyContext';
import '../../css/custom-scrollbar.css';

const UpdateKYC = () => {
  const { isDarkMode, isToggleSidebar } = useMyContext();

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768 
                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            } >
            <Sidebar />
          </div>
        )}

        <div className="w-full p-8 dark:bg-[rgba(1,14,78,0.96)] font-sans flex flex-col md:flex-row gap-8 h-full items-stretch">
          <div className="w-full md:w-4/12 p-6 rounded-custom dark:bg-[rgba(1,14,78,0.96)] shadow-custom-light dark:shadow-custom-dark flex flex-col justify-between h-full">
            <button className="w-full bg-customPurple text-white py-2 rounded-custom dark:shadow-custom-dark shadow-custom-light mb-6">
              Update Your KYC
            </button>
            <form className="space-y-4 dark:bg-[rgba(1,14,78,0.96)] overflow-y-auto custom-scrollbar h-full">
              <div>
                <label className="block text-sm font-medium dark:text-white">Company Name</label>
                <input type="text" className="mt-1 block w-full border border-gray-300 rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2" />
              </div>
              <div>
                <label className="block text-sm font-medium dark:text-white">Company Type</label>
                <select className="mt-1 block w-full border border-gray-300 rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2">
                  <option>Select Company Type</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium dark:text-white">GSTIN</label>
                <input type="text" className="mt-1 block w-full border border-gray-300 rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2" />
              </div>
              <div>
                <label className="block text-sm font-medium dark:text-white">Required Document</label>
                <select className="mt-1 block w-full border border-gray-300 rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2">
                  <option>Select Valid Document</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium dark:text-white">KYC Number</label>
                <input type="text" className="mt-1 block w-full dark:bg-[rgba(1,14,78,0.96)] border border-gray-300 rounded-custom dark:text-white p-2" />
              </div>
              <div>
                <label className="block text-sm font-medium dark:text-white">Select Docs</label>
                <div className="mt-2 space-x-4">
                  <label className="inline-flex items-center">
                    <input type="radio" className="form-radio dark:bg-[rgba(1,14,78,0.96)]" name="document" value="pan" />
                    <span className="ml-2 dark:text-white">Pan</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input type="radio" className="form-radio dark:bg-[rgba(1,14,78,0.96)]" name="document" value="aadhaar" />
                    <span className="ml-2 dark:text-white">Aadhaar</span>
                  </label>
                </div>
              </div>
              <button type="button" className="w-full bg-purple-100 text-customPurple p-2 rounded-md hover:bg-purple-200 transition flex items-center justify-center">
                <Upload className="mr-2" size={20} />
                Upload File
              </button>
              <button type="submit" className="w-full bg-customPurple text-white p-2 rounded-md hover:bg-purple-700 transition">Submit</button>
            </form>
          </div>

          <div className="w-full md:w-9/12 p-6 rounded-custom flex flex-col h-full shadow-custom-light dark:shadow-custom-dark">
      <img
        src={isDarkMode ? "/assets/images/Less_dark.png" : "/assets/images/Less.jpg"}
        alt="Image Description"
        className="rounded-custom shadow-custom-light dark:shadow-custom-dark h-full object-cover"
      />
    </div>

        </div>
      </div>
    </div>
  );
};

export default UpdateKYC;
