import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { IoIosCloseCircle } from "react-icons/io";
import { FiUploadCloud, FiDownload } from "react-icons/fi";
import axios from 'axios';
import { Base_Url, csvuplode } from '../../config/config';
import toast from 'react-hot-toast';
import { useMyContext } from '../../context/MyContext';

const UploadBulk = ({ isopen, onClose }) => {
    const [file, setFile] = useState(null);
    const { isDarkMode } = useMyContext();
    const [isLoading,isSetLoading] = useState(false );

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === 'text/csv') {
            setFile(selectedFile);
        } else {
            toast.error('Please select a valid CSV file.');
            event.target.value = null;
        }
    };

    const handleClose = () => {
        onClose();
        setFile(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem('token');
        const cus_id = localStorage.getItem('cus_id');
        const shipment_type = "forword";

        if (file) {
            const formData = new FormData();
            formData.append('csv_file', file);
            formData.append("shipment_type", shipment_type);

            try {
                const response = await axios.post(Base_Url + csvuplode, formData, {
                // const response = await axios.post ("http://192.168.1.36/backendci4/api/csvuplode    ",formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        // 'Content-Type': 'application/json',  
                        'token': token,
                        'cus_id': cus_id,
                    },
                });
                setFile(null);
                event.target.reset();
                if (response.data.status === "success") {
                    toast.success(response.data.message || "File uploaded successfully!");
                     handleClose();
                } else {
                    toast.error("File uploading failed");
                }
            } catch (error) {
                toast.error("Error uploading file: " + error.message);
            }
        }
    };

    return (
        <Dialog
            open={isopen}
            onClose={onClose}
            maxWidth="sm"
            sx={{
                '& .MuiDialog-paper': {
                    width: '80%',
                    height: '80vh',
                }
            }}
        >
            <DialogTitle className="bg-customPurple text-white text-center">
                <h2 className="text-2xl font-semibold flex items-center justify-center">
                    Upload Bulk Order
                </h2>
                <IoIosCloseCircle
                    className="absolute top-2 right-2 cursor-pointer"
                    size={32}
                    onClick={handleClose}
                />
            </DialogTitle>

            <DialogContent
                className={`shadow-custom-light rounded-custom dark:shadow-custom-dark mt-4 ml-2 mr-2 mb-4 p-4 
                ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white" : ""}`}
                sx={{ overflowY: 'auto' }}
            >
                <div className={`overflow-x-auto md:mt-8 md:mb-8 md:ml-8 md:mr-8 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white" : ""}`}>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="flex flex-col items-center justify-center w-full">
                            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer  transition-colors">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <FiUploadCloud className="w-12 h-12 mb-4 text-gray-400" />
                                    <p className="mb-2 text-sm text-gray-500 dark:text-white"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p className="text-xs text-gray-500 dark:text-white">CSV file only</p>
                                </div>
                                <input id="dropzone-file" type="file" className="hidden" accept=".csv" onChange={handleFileChange} />
                            </label>
                        </div>
                        {file && (
                            <p className="text-sm text-gray-600 font-medium">Selected file: {file.name}</p>
                        )}
                        
                        <button type="submit" className="w-full px-4 py-2 text-white bg-customPurple rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition-all duration-300">
                            Upload CSV
                        </button>
                    </form>

                    <div className="mt-6 text-center">
                        <a
                            href="/sampleFile/sample.csv"
                            download="sample.csv"
                            className="w-full inline-flex items-center justify-center px-4 py-2 text-white bg-customPurple rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition-all duration-300"
                        >
                            <FiDownload className="w-5 h-5 mr-2" />
                            Download Sample CSV
                        </a>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default UploadBulk;
