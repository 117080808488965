
import React, { useState,useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Spinner from '../../component/spinner/spinner'
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-hot-toast';
import { useAuth } from '../../routes/Auth';
import { Base_Url, loginapi } from '../../config/config';

const schema = z.object({
  email: z.string().email('Invalid email address').nonempty('Email is required'),
  password: z.string().min(6, 'Password must be at least 6 characters long').nonempty('Password is required')
});

const LoginPage = ({setAuth}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isshowPassword, setIsshowPassword] = useState(false);
  const { login } = useAuth();

 const navigate= useNavigate();


//  useEffect(()=>{
//   const token=localStorage.getItem('authToken');
//   if(token){
//     navigate('/dashboard')
//   }
 
// },[])

   const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });



  const onSubmit = (data) => {
     
    setIsSubmitting(true)
    axios.post(Base_Url + loginapi, {
    // axios.post ("http://192.168.1.36/backendci4/api/login",{
      username: data.email,
      password: data.password,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function (response) {
        setIsSubmitting(false)

        if(response.data.status ==='SUCCESS')

        {
          login(true)
          localStorage.setItem('token',response.data.userData.token)
          localStorage.setItem('cus_id',response.data.userData.cus_id)
          localStorage.setItem('user', JSON.stringify(response.data));
          toast.success(response.data.message)
          navigate("/dashboard");
        }
        else{
         
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        setIsSubmitting(false)

      });
  }


  return (
    <section className="flex flex-col items-center justify-center h-full">
      <div style={{ backgroundImage: `url('/assets/images/dp.jpg')` }}
        className='-z-10 h-full w-full opacity-10 fixed top-0 left-0 pointer-events-none'>
      </div>
      <div className="text-center mb-4">
        <img className="mx-auto" src="/assets/images/logo1.png" width="180" height="150" alt="Logo" />
        <h5 className="text-xl font-sans" style={{ fontFamily: 'Open Sans, Public Sans, sans-serif' }}>Login to PICKUPXPRESS</h5>
      </div>
      <div className="w-950 max-w-md bg-gray-100 p-6 rounded-xl mb-10 border-gray-200 border font-public-sans shadow-lg" >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative mb-5 font-public-sans">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon icon={faEnvelope} className="text-black-300" />
            </span>
            <input
              type="text"
              className={`w-full pl-10 pr-3 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 focus:shadow-md focus:shadow-purple-400 placeholder-gray-500 placeholder:text-sm font-public-sans`}
              placeholder="Enter your email"
              {...register('email')}
            />
            {errors.email && (
              <p className="text-red-500 text-xs absolute top-full mt-0 left-0 w-full">
                {errors.email.message}
              </p>
            )}
          </div>

          <div className="relative mb-5 font-public-sans">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon icon={faLock} className="text-black-300" />
            </span>
            <input
              type={isshowPassword ? 'text' : 'password'}
              className={`w-full pl-10 pr-3 py-2 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 focus:shadow-md focus:shadow-purple-400 placeholder-gray-500 placeholder:text-sm font-public-sans`}
              placeholder="Enter your password"
              {...register('password')}
            />
            <span
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={() => setIsshowPassword(!isshowPassword)}
            >
              <FontAwesomeIcon icon={isshowPassword ? faEye : faEyeSlash} />
            </span>
            {errors.password && (
              <p className="text-red-500 text-xs absolute top-full mt-0 left-0 w-full">
                {errors.password.message}
              </p>
            )}
          </div>
          <button
            className="w-full bg-blueMain text-white py-2 px-4 rounded-md hover:bg-blue-600 text-sm font-open-sans font-bold items-center justify-center flex"
            type="submit"

          >
            {isSubmitting ? <Spinner /> : 'SIGN IN'}
          </button>
          <div className="text-center mt-4">
            <a href="/forgot-password" className="text-black hover:text-blueMain text-xs font-open-sans font-semibold">
              FORGOT PASSWORD
            </a>
          </div>
          <div className="text-center mt-4 flex items-center justify-center">
            <span className="border-t border-darkBlue flex-grow h-0.5"></span>
            <span className="text-white font-bold font-open-sans w-100 h-100 px-2 py-1 rounded-full bg-darkBlue text-md">or</span>
            <span className="border-t border-darkBlue flex-grow h-0.5"></span>
          </div>

          
  {/* Wrapping the GoogleLogin with a div to center it */}
  {/* <div className="flex items-center justify-center mt-4">
    <GoogleLogin
      onSuccess={credentialResponse => {
        toast("Login Successfully"
        //   autoClose: 5000,
        //   transition: Bounce,
        //   closeButton: false,
        //   progress: undefined,
        );
        try {
          const googleDetails = jwtDecode(credentialResponse.credential);
          navigate('/dashboard'); 
          localStorage.setItem('authToken', 'sdhsdgsdgsdgs');
          setAuth(true)
        } catch (error) {
          console.error(" Error:", error);
        }
      }}
    />
  </div> */}

        </form>
      </div>
      <div className="max-w-md bg-gray-100 p-6 rounded-xl mb-5 md:mb-0 lg:mb-8  border font-public-sans shadow-lg w-950">
        <span className="text-black">
          Don't have an account? {'  '}
          <a href="/" className="text-blue-500 hover:underline">
            Register
          </a>
        </span>
      </div>
    </section>
  );
};

export default LoginPage;
