import { IoMdEye } from 'react-icons/io';
import { useEffect, useState } from 'react';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import { Button } from '@mui/material';
import { FcPrint } from "react-icons/fc";
import Pagination from '@mui/material/Pagination';
import { FaWeight } from 'react-icons/fa';
import toast from 'react-hot-toast';
import PaginationItem from '@mui/material/PaginationItem';
import { useMyContext } from '../../../context/MyContext';
import '../../../css/custom-scrollbar.css';
import { Base_Url, forwardorderlist, shipmentlabel } from '../../../config/config';
import ViewAllShipment from '../../../component/models/ViewAllShipment';

const AllShipment = () => {
  const [activeTab, setActiveTab] = useState('new order');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const token = localStorage.getItem('token');
  const cusid = localStorage.getItem('cus_id');
  const [selectedShipment, setSelectedShipment] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10); 

  useEffect(() => {
    fetchOrderData();
  }, [page, limit]); 

  const fetchOrderData = async () => {
    try {
      const pageNumber = Number(page) || 1;
      const limitNumber = Number(limit) || 10;
      const url = `${Base_Url}${forwardorderlist}?page=${pageNumber}&limit=${limitNumber}`;
      // const url = `http://192.168.1.36/backendci4/api/forwardorderlist?page=${pageNumber}&limit=${limitNumber}`;


      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token || '',
          cus_id: cusid || '',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch data');
      }

      const result = await response.json();

      if (result && result.data) {
        setData(result.data);
        setTotalOrders(result.pagination.totalItems || 0);
      } else {
        console.error('Unexpected response structure:', result);
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('API error:', error.message);
      toast.error('Error fetching order data: ' + error.message);
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const newOrders = data.filter(order => {
    const createdAt = new Date(order.created_at);
    return createdAt >= today; 
  });

  // Update totalOrders to reflect the count of newOrders
  const totalNewOrders = newOrders.length;

  const handleRowSelect = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const handleViewClick = (row) => {
    setSelectedShipment(row);
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handlePrintLabel = async (orderId) => {
    try {
      const response = await fetch(`${Base_Url}${shipmentlabel}`, {
        method: 'POST',
        headers: {
          token,
          cus_id: cusid,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ order_id: orderId }),
      });

      const data = await response.json();
      const printUrl = data?.DownlodeLink;
      if (printUrl) {
        window.open(printUrl, '_blank');
        toast.success("Shipment label downloaded successfully!");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('API error: ' + error.message);
    }
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
  };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768
                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>
            <Sidebar />
          </div>
        )}
        <div className={`shadow-custom-light ml-3 mt-6 mb-6 mr-6 w-full rounded-custom overflow-y-auto dark:shadow-custom-dark p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : ''}`}>
          <button className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
            <FaWeight className="mr-2" size={24} />
            All Orders
          </button>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            {['new order', 'process order', 'manifest list', 'all orders'].map((tab) => (
              <button
                key={tab}
                className={`px-4 py-2 mr-8 w-full mb-2 rounded dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark ${activeTab === tab ? 'bg-customPurple hover:bg-purple-700 text-white dark:bg-customPurple dark:text-white' : 'dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark shadow-custom-light rounded-custom'}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>

          {activeTab === 'new order' && (
            <div className={`rounded-custom overflow-x-auto p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : 'bg-white'}`}>
              <table className="min-w-full w-full table-auto custom-scrollbar overflow-y-auto">
                <thead className="bg-[#0540a6dd] text-white text-[14px]">
                  <tr>
                    <th className="p-2 text-left">
                      <input type="checkbox" checked={selectAll} />
                    </th>
                    <th className="p-2 text-left">Order ID</th>
                    <th className="p-2 text-left">Partner</th>
                    <th className="p-2 text-left">Customer Info</th>
                    <th className="p-2 text-left">Amount</th>
                    <th className="p-2 text-left">Shipping Mode</th>
                    <th className="p-2 text-left">Waybill</th>
                    <th className="p-2 text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {newOrders.slice((page - 1) * limit, page * limit).map((row, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-2">
                        <input type="checkbox" checked={selectedRows.includes(index)} onChange={() => handleRowSelect(index)} />
                      </td>
                      <td className="p-2">{row.order_id}</td>
                      <td className="p-2">{row.partner}</td>
                      <td className="p-2">{row.first_name + ' ' + row.last_name}</td>
                      <td className="p-2">{row.total_amount}</td>
                      <td className="p-2">{row.shipping_mode}</td>
                      <td className="p-2">{row.waybill}</td>
                      <td className="p-2 flex space-x-2">
                      <Button onClick={() => handleViewClick(row)} variant="contained" color="primary">
                          <IoMdEye />
                        </Button>
                        <Button onClick={() => handlePrintLabel(row.order_id)} variant="contained" color="secondary">
                          <FcPrint />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
              <Pagination
                count={Math.ceil(totalNewOrders / limit)} 
                page={page}
                onChange={handlePaginationChange}
                renderItem={(item) => (
                  <PaginationItem
                    component="button"
                    {...item}
                    sx={{
                      borderRadius: '8px',
                      margin: '0 5px',
                    }}
                  />
                )}
              />
            </div>
          )}
          {activeTab === 'all orders' && (
            <div className={`rounded-custom overflow-x-auto  custom-scrollbar p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : 'bg-white'}`}>
              <table className="min-w-full w-full table-auto  overflow-y-auto">
                <thead className="bg-[#0540a6dd] text-white text-[14px]">
                  <tr>
                    <th className="p-2 text-left">
                      <input type="checkbox" checked={selectAll} />
                    </th>
                    <th className="p-2 text-left">Order ID</th>
                    <th className="p-2 text-left">Partner</th>
                    <th className="p-2 text-left">Customer Info</th>
                    <th className="p-2 text-left">Amount</th>
                    <th className="p-2 text-left">Shipping Mode</th>
                    {/* <th className="p-2 text-left">Dimensions</th> */}
                    <th className="p-2 text-left">Waybill</th>
                    <th className="p-2 text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-2">
                        <input type="checkbox" checked={selectedRows.includes(index)} onChange={() => handleRowSelect(index)} />
                      </td>
                      <td className="p-2">{row.order_id}</td>
                      <td className="p-2">{row.partner}</td>
                      <td className="p-2">{row.first_name + " " + row.last_name}</td>
                      <td className="p-2">{row.total_amount}</td>
                      <td className="p-2">{row.shipping_mode}</td>
                      {/* <td className="p-2">{row.dimensions}</td> */}
                      <td className="p-2">{row.waybill}</td>
                      <td className="p-2 flex gap-2">
                        <Button onClick={() => handleViewClick(row)} variant="contained" color="primary">
                          <IoMdEye />
                        </Button>
                        <Button onClick={() => handlePrintLabel(row.order_id)} variant="contained" color="secondary">
                          <FcPrint />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                <span className="text-sm text-gray-700 font-bold dark:text-gray-300">{`Total Orders: ${totalOrders}`}</span>
                <select
                  id="limit"
                  value={limit}
                  onChange={handleLimitChange}
                  className="border rounded p-1 ml-[480px] text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={totalOrders}>All</option>
                </select>
                <Pagination
                  count={Math.ceil(totalOrders / limit)}
                  page={page}
                  onChange={handlePaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination"
                  size="small"
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      className={`mx-1 rounded-md ${item.selected ? 'bg-customPurple text-white' : 'text-gray-700 dark:text-gray-300'} hover:bg-purple-700 hover:text-white`}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <ViewAllShipment isopen={isPopupOpen} onClose={handleClose} shipment={selectedShipment} />
        </div>
      </div>
    </div>
  );
};

export default AllShipment;
