import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Toaster } from "react-hot-toast";
import reportWebVitals from './reportWebVitals';
import { MyProvider } from './context/MyContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="287244027272-vcqskb3rfegv6kmt1isfilveo8p1fggu.apps.googleusercontent.com">
    <MyProvider>
    <Toaster position="top-right"/>
      <App />
    </MyProvider>
    </GoogleOAuthProvider>

);

reportWebVitals();
