"use client";
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../../component/navBar/navBar';
import { useMyContext } from '../../../context/MyContext';
import Sidebar from '../../../component/sidebar/sidebar';
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa';
import '../../../css/custom-scrollbar.css';

const WalletRecharge = () => {
  const [amount, setAmount] = useState('');
  const [coupon, setCoupon] = useState('');
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const navigate = useNavigate();

  const currentBalance = 5000;

  const handleRecharge = (e) => {
    e.preventDefault();
  };

  const viewHistory = () => {
    navigate('/payments/topuphistory');
  };

  const viewShipmentCharges = () => {
    navigate('/payments/shippingCharges');
  };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
    <div className="z-10">
      <NavBar />
    </div>

    <div className="flex flex-1 overflow-hidden">
      {isToggleSidebar && (
        <div
          className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
          style={
            window.innerWidth < 768 
              ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
              : {}
          }  >
          <Sidebar />
        </div>
      )}
        <div className="container mx-auto p-6 w-full bg-white rounded-custom shadow-custom-light dark:shadow-custom-dark flex flex-col md:flex-row dark:bg-[rgba(1,14,78,0.96)] font-sans md:ml-4 md:mt-4 md:mb-6 md:mr-4 custom-scrollbar overflow-y-auto">
          <div className="container mb-0 md:mb-0 mx-auto p-6 bg-white dark:bg-[rgba(1,14,78,0.96)] shadow-custom-light dark:shadow-custom-dark rounded-custom md:max-w-[80%] w-full overflow-y-auto custom-scrollbar min-h-full">
            <div className="mb-6 text-center">
              <button className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
                <FaCcVisa className="mr-2" size={24} />
                <FaCcMastercard className="mr-2" size={24} />
                Wallet Recharge
              </button>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-6 ">
              <div className="p-4 rounded-custom text-center shadow-custom-light dark:shadow-custom-dark text-customPurple dark:text-white ">
                <h3 className="text-sm font-medium ">Current Balance</h3>
                <p className="text-2xl font-bold ">₹{currentBalance}</p>
              </div>
              <div className="text-customPurple dark:text-white p-4 rounded-custom text-center shadow-custom-light dark:shadow-custom-dark">
                <h3 className="text-sm font-medium">Available Credits</h3>
                <p className="text-2xl font-bold">₹2000</p>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-6">
              <Button
                onClick={viewShipmentCharges}
                variant="contained"
                sx={{
                  backgroundColor: 'hsl(265, 58%, 58%)',
                  color: 'white',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: 'hsl(270, 67%, 39%)',
                  },
                }}
                className="flex-1 font-sans text-sm"
              >
                Shipment Charges
              </Button>

              <Button
                onClick={viewHistory}
                variant="contained"
                sx={{
                  backgroundColor: 'hsl(265, 58%, 58%)',
                  color: 'white',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: 'hsl(270, 67%, 39%)',
                  },
                }}
                className="flex-1 font-sans bg-customPurple hover:bg-purple-700 capitalize text-sm"
              >
                View Topup History
              </Button>
            </div>

            <form onSubmit={handleRecharge} className="space-y-4 dark:text-white">
              <div>
                <label
                  htmlFor="amount"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Custom Amount
                </label>
                <input
                  type="number"
                  id="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter amount"
                  className="mt-1 p-2 border border-gray-300 shadow-custom-light dark:shadow-custom-dark w-full rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="coupon"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Apply Coupon (optional)
                </label>
                <input
                  type="text"
                  id="coupon"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                  placeholder="Enter coupon code"
                  className="mt-1 p-2 border border-gray-300 shadow-custom-light dark:shadow-custom-dark w-full rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white"
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: 'hsl(265, 58%, 58%)',
                  color: 'white',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: 'hsl(270, 67%, 39%)',
                  },
                }}
                className="w-full font-sans bg-customPurple hover:bg-purple-700 capitalize py-2 text-sm"
              >
                Recharge
              </Button>
            </form>
          </div>

          <div className="w-full mt-4 md:mt-0 md:w-12/12 p-6 min-h-full rounded-custom flex flex-col dark:bg-[rgba(1,14,78,0.96)] dark:text-white shadow-custom-light dark:shadow-custom-dark font-sans md:ml-2  custom-scrollbar overflow-y-auto">
  <h1 className="text-red-500 font-semibold font-sans text-xs">
    Terms & Conditions to using Wallet Top-Up
  </h1>

  <p className="font-medium font-sans text-xs mt-2">
    These Terms & Conditions ("T&Cs") govern the top-up of funds
    ("Top-Up") into your wallet account ("Wallet") on PICKUPXPRESS. By
    topping up your Wallet, you agree to these T&Cs.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">1. Eligibility</h3>
  <p className="font-medium font-sans text-xs">
    * You must be a registered user of the PICKUPXPRESS to top up your
    Wallet.
    <br />
    * You must comply with all applicable laws and regulations when
    topping up your Wallet.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">2. Top-Up Process</h3>
  <p className="font-medium font-sans text-xs">
    * To add funds to your Wallet, you may use the available payment
    methods supported by the PICKUPXPRESS, including credit/debit cards,
    bank transfers, Wallet/UPI.
    <br />
    * All Top-Up transactions are final and cannot be reversed or refunded
    unless required by law or expressly stated in these T&Cs.
    <br />
    * The minimum and maximum amounts for a Top-Up are subject to limits
    as determined by the Platform from time to time.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">3. Processing of Funds</h3>
  <p className="font-medium font-sans text-xs">
    * Funds topped up into your Wallet will show in your balance within
    0-36 hours depending on the payment method used.
    <br />
    * The Platform is not responsible for delays caused by the payment
    processing service or other third parties.
    <br />
    * The Wallet balance is non-transferable and can only be used for
    services on the PICKUPXPRESS platform.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">4. Fees and Charges</h3>
  <p className="font-medium font-sans text-xs">
    * The Platform may charge fees for Top-Up transactions, which will be disclosed at the time of the transaction.
    <br />
    * Any applicable fees or taxes, including foreign exchange fees for cross-border transactions, will be borne by the user.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">5. Usage of Wallet Funds</h3>
  <p className="font-medium font-sans text-xs">
    * Funds in your Wallet can only be used for transactions within the PICKUPXPRESS.
    <br />
    * Wallet funds cannot be withdrawn, transferred, or redeemed for cash, except where required by law or as specified by the PICKUPXPRESS.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">6. Account Security</h3>
  <p className="font-medium font-sans text-xs">
    * You are responsible for maintaining the security of your account and ensuring that your login credentials are not shared with others.
    <br />
    * The Platform is not liable for any unauthorized access to your account or Wallet due to your negligence.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">7. Expiration and Forfeiture of Funds</h3>
  <p className="font-medium font-sans text-xs">
    * Wallet balances may expire or be subject to forfeiture after a specified period of inactivity as determined by the PICKUPXPRESS policies.
    <br />
    * The Platform will provide reasonable notice before any such expiration or forfeiture of funds.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">8. Fraud Prevention</h3>
  <p className="font-medium font-sans text-xs">
    * The PICKUPXPRESS reserves the right to suspend or terminate your account and Wallet, and to investigate any Top-Up transaction suspected of being fraudulent or in violation of these T&Cs.
    <br />
    * Any attempt to defraud the PICKUPXPRESS or misuse the Wallet will result in legal action and forfeiture of funds.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">9. Changes to Terms & Conditions</h3>
  <p className="font-medium font-sans text-xs">
    * The Platform reserves the right to modify these T&Cs at any time. Any changes will be communicated to users through the PICKUPXPRESS or via email.
    <br />
    * Continued use of the Wallet after changes to these T&Cs will constitute acceptance of the modified terms.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">10. Governing Law</h3>
  <p className="font-medium font-sans text-xs">
    * Any disputes arising out of or related to these T&Cs will be subject to the exclusive jurisdiction of the courts of Gautam Budh Nagar/India.
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">11. Contact Information</h3>
  <p className="font-medium font-sans text-xs">
    If you have any questions or concerns regarding these T&Cs, please contact our support team at [support@pickupxpress.com].
  </p>

  <h3 className="font-semibold font-sans text-xs mt-2">Payment Methods Supported for PICKUPXPRESS Wallet-TopUp:</h3>
  <p className="font-medium font-sans text-xs mt-2">
    * Credit/Debit Cards:
    <br />
    • Visa
    <br />
    • MasterCard
    <br />
    • American Express
    <br />
    • Discover
  </p>
  <p className="font-medium font-sans text-xs mt-2">
    * Digital Wallets:
    <br />
    • Paytm
    <br />
    • Google Pay
    <br />
    • PhonePe
  </p>
</div>

        </div>
      </div>
    </div>
  );
};

export default WalletRecharge;
