import { useState,useRef, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../../component/navBar/navBar'
import Sidebar from '../../../component/sidebar/sidebar';
import { useForm } from 'react-hook-form';
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaCalculator, FaUser, FaShoppingCart, FaBox } from 'react-icons/fa';
import { useMyContext } from '../../../context/MyContext';
import '../../../css/custom-scrollbar.css';
import Select from "react-dropdown-select";
import ViewRateForword from '../../../component/models/ViewRateForwordShipmet';
import { Base_Url, Warehouselist, getpincode, priceEstimator } from '../../../config/config';
import { toast } from 'react-hot-toast';
import Spinner from '../../../component/spinner/spinner';
import axios from 'axios';
import UploadBulk from '../../../component/models/UploadBulk';

const schema = z.object({
    warehouse: z.string().optional(),
    firstName: z.string().min(1, "First Name is required").regex(/^[a-zA-Z\s]+$/, "First Name must only contain alphabetic characters"),
    lastName: z.string().min(1, "Last Name is required").regex(/^[a-zA-Z\s]+$/, "Last Name must only contain alphabetic characters"),
    email: z.string().email("Invalid email address"),
    mobile: z.string()
        .length(10, "Mobile number must be exactly 10 digits")
        .regex(/^\d+$/, "Mobile number must be numeric"),
    address: z.string().min(10, "Full Addrss must be atleast 10 char"),
    pincode: z.string()
        .min(6, "Pincode should be exactly 6 characters")
        .max(6, "Pincode should be exactly 6 characters")
        .regex(/^\d+$/, "Pincode must be numeric"),
    city: z.string().min(1, "City is required").regex(/^[a-zA-Z\s]+$/, "City must only contain alphabetic characters"),
    state: z.string().min(1, "State is required").regex(/^[a-zA-Z\s]+$/, "State must only contain alphabetic characters"),
    orderId: z.string().min(1, "Order ID is required"),
    invoiceAmount: z.string().min(1, "Invoice Amount is required"),
    paymentMode: z.string().min(1, "Payment Mode is required"),
    productDes: z.string().min(1, "Product Descripition is required"),
    codAmount: z.string().optional(),
    // boxQty: z.string().min(1, "Number of Boxes is required").regex(/^\d+$/, "Box Quantity must be positive"),
    weight: z.string()
        .min(1, "weight is required")
        .regex(/^\d*\.?\d+$/, "weight must be a positive number and can be a float"),
    length: z.string()
        .min(1, "Length is required")
        .regex(/^\d*\.?\d+$/, "Length must be a positive number and can be a float"),
    width: z.string()
        .min(1, "Width is required")
        .regex(/^\d*\.?\d+$/, "Width must be a positive number and can be a float"),
    height: z.string()
        .min(1, "Height is required")
        .regex(/^\d*\.?\d+$/, "Height must be a positive number and can be a float")
});

export default function ReverseShipment() {
    const { isDarkMode, isToggleSidebar } = useMyContext()
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tableData, setTableData] = useState(null);
    const Navigation = useNavigate();
    const [wareHouseList, setwareHouseList] = useState([])
    const [wareHouseId, setwareHouseId] = useState('')
    const [wareHousePincode, setwareHousePincode] = useState('');
    const [reqBody, setReqBody] = useState([]);
    const [uploadBulkPopupOpen, setUploadBulkPopupOpen] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
        resolver: zodResolver(schema),
    });


    const handleViewClick = () => {
        setIsPopupOpen(true);
    };
    const handleClose = () => {
        setIsPopupOpen(false);
    };


    const paymentMode = watch("paymentMode");
   
    useEffect(() => {
        if (paymentMode === "prepaid") {
            setValue("codAmount", "0");
        } else if (paymentMode === "cod") {
            setValue("codAmount", "");
        }
    }, [paymentMode, setValue]);

    const handleInputChange = (event) => {
        const { value, id } = event.target;

        if (['pincode', 'mobile', 'boxQty'].includes(id)) {
            const filteredValue = value.replace(/[^0-9]/g, '');
            event.target.value = filteredValue;
        }

        // Allow only letters and spaces for text fields
        if (['firstName', 'lastName', 'city', 'state'].includes(id)) {
            const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
            event.target.value = filteredValue;
        }
    };
    useEffect(() => {
        fetchWareHouseList()
    }, [])

    const fetchWareHouseList = async () => {

        const token = localStorage.getItem('token');
        const cusid = localStorage.getItem('cus_id');


        const headers = new Headers({
            'token': token,
            'cus_id': cusid,
            'Content-Type': 'application/json'
        });

        try {
            const response = await fetch(Base_Url + Warehouselist, {

                method: 'GET',
                headers: headers
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const transformedData = data.data.map(warehouse => ({
                value: warehouse.id,
                label: warehouse.name,
                pincode: warehouse.pincode
            }));
            setwareHouseList(transformedData)
        } catch (error) {
            console.error('Error:', error);
        }
    }


    const handleNaviagtion = () => {
        Navigation("/dashboard");

    }

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const token = localStorage.getItem('token');
        const cus_id = localStorage.getItem('cus_id');

        const requestBody = {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            address: data.address,
            products_desc: data.productDes,
            phone: data.mobile,
            city: data.city,
            state: data.state,
            country: "India",
            payment_mode: data.paymentMode,
            total_amount: data.invoiceAmount,
            address_type: "home",
            length: data.length,
            width: data.width,
            height: data.height,
            from_pincode: wareHousePincode,
            to_pincode: data.pincode,
            cod_amount: data.codAmount,
            quantity: "1",
            weight: data.weight,
            warehouse_id: wareHouseId,
        };

        setReqBody(requestBody);
        try {
            const response = await axios.post(Base_Url + priceEstimator, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token,
                    'cus_id': cus_id,
                },
            });
            if (response.data.status === "success") {
                toast.success(response.data.message);
                setTableData(response.data);
                reset();
                handleViewClick();
                isSubmitting(false);
            } else {
                toast.error(response.data.message);
                isSubmitting(false);
            }
        } catch (error) {
            setIsSubmitting(false);
        }
    };


    const handleSelectChange = (selected) => {
        if (selected.length > 0) {
            const selectedItem = selected[0];
            setwareHouseId(selectedItem.value);
            setwareHousePincode(selectedItem.pincode);
        }
    };


    const UploadBulkViewClick = () => {
        setUploadBulkPopupOpen(true);

    }

    const handleCloseUploadBulk = () => {
        setUploadBulkPopupOpen(false);

    };
   

    const token = localStorage.getItem('token');
    const cus_id = localStorage.getItem('cus_id');

    ;

     const pincode = watch("pincode");

    useEffect(() => {
        if (pincode && pincode.length === 6) {
            fetchPincodeData(pincode);
        }
    }, [pincode]);


    const fetchPincodeData = async (pincode) => {
        const urlgetPincode = `${Base_Url}${getpincode}?pincode=${pincode}`
        try {
            // const response = await fetch(`http://192.168.1.36/backendci4/api/getpincode?pincode=${pincode}`, {
                const response = await fetch(urlgetPincode, {
                method: 'GET',
                headers: {
                    // 'token': "eyJ1c2VyX2lkIjoiNTkiLCJleHBpcmVzX2F0IjoxNzI4NTc0MjE3fQ==",
                    'token':token,
                    'cus_id': cus_id,
                    'Content-Type': 'application/json'
                }
            });
    
    
            const data = await response.json();
            if (data && data.status === "SUCCESS") {
                let { city, state } = data.data[0];
        
                city = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();
                state = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase();
                setValue("city", city);
                setValue("state", state);
            }
                else if (data && data.status === "FAIL")
            {
                toast.error("Something Went Wrong Please Try Again With Another Pincode");
            }
             else {
                toast.error("Failed to fetch city and state data");
            }
        } catch (error) {
            console.error('Error fetching pincode data:', error);
            toast.error("Error fetching pincode data");
        }
    };
    return (
        <>
            <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
                <div className="z-10">
                    <NavBar />
                </div>

                <div className="flex flex-1 overflow-hidden">
                    {isToggleSidebar && (
                        <div
                            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
                            style={
                                window.innerWidth < 768
                                    ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                                    : {}
                            } >
                            <Sidebar />
                        </div>
                    )}

                    <div
                        // {`${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white shadow-none" : ""} w-full  overflow-auto transition-all duration-300  shadow-custom-light rounded-custom }`}
                        className={`flex-1 sm:mt-3 overflow-auto transition-all duration-300 custom-scrollbar ${isToggleSidebar ? 'md:ml-0' : 'md:ml-0'} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-white shadow-custom-dark" : "bg-white"}`}
                    >
                        <div className="container mx-auto pl-6 pr-6 pt-4 pb-2 ">
                            <div className="flex flex-col md:flex-row mb-3 gap-2 md:gap-8">
                                <button onClick={handleNaviagtion}
                                    className="w-full md:w-[48%] bg-customPurple hover:bg-purple-700 text-white py-2 rounded-md flex items-center justify-center">
                                    <IoMdArrowRoundBack className="mr-2" />
                                    Create Reverse Order
                                </button>

                                <button
                                    className="w-full md:w-[48%] bg-customPurple hover:bg-purple-700 text-white py-2 rounded-md flex items-center justify-center"
                                    onClick={UploadBulkViewClick}
                                >
                                    <FaBox className="mr-2" />
                                    Upload Bulk
                                </button>
                            </div>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="px-8 pt-6 pb-8 mb-4 font-sans shadow-custom-light dark:shadow-custom-dark  rounded-custom w-full">
                                    <h2 className="text-xl font-bold mb-4 text-custom-blue flex items-center">
                                        <FaUser className="mr-2 text-custom-blue" />
                                        Customer Details
                                    </h2>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                                        <div>
                                            <label htmlFor="warehouse" className="block text-sm font-medium mb-1">Warehouse</label>
                                            {/* <Select options={options} onChange={(values) => this.setValues(values)} />; */}

                                            <Select options={wareHouseList} style={{
                                                padding: '0.5rem',
                                                appearance: 'none',
                                                borderRadius: '0.375rem',
                                                width: '100%',
                                                outline: 'none',
                                                backgroundColor: isDarkMode ? 'rgba(1, 14, 78, 0.96)' : '',
                                                color: isDarkMode ? 'white' : '',
                                                placeholderColor: isDarkMode ? 'white' : '',
                                            }}
                                                onChange={handleSelectChange}
                                            />

                                            {errors.warehouse && <p className="text-red-500 text-sm mt-1">{errors.warehouse.message}</p>}
                                        </div>

                                        <div>
                                            <label htmlFor="firstName" className="block text-sm font-medium mb-1">First Name</label>
                                            <input
                                                id="firstName"
                                                {...register("firstName")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                onChange={handleInputChange}
                                                placeholder="Enter first name"
                                            />
                                            {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="lastName" className="block text-sm font-medium mb-1">Last Name</label>
                                            <input
                                                id="lastName"
                                                {...register("lastName")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                onChange={handleInputChange}
                                                placeholder="Last name"
                                            />
                                            {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium mb-1">Email</label>
                                            <input
                                                id="email"
                                                {...register("email")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="email"
                                                placeholder="Enter email"
                                            />
                                            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="mobile" className="block text-sm font-medium mb-1">Mobile No.</label>
                                            <input
                                                id="mobile"
                                                maxLength="10"
                                                {...register("mobile")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="tel"
                                                onChange={handleInputChange}
                                                placeholder="Enter 10 digit mobile no."
                                            />
                                            {errors.mobile && <p className="text-red-500 text-sm mt-1">{errors.mobile.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="address" className="block text-sm font-medium mb-1">Full Address</label>
                                            <input
                                                id="address"
                                                {...register("address")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                placeholder="Enter full address"
                                            />
                                            {errors.address && <p className="text-red-500 text-sm mt-1">{errors.address.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="pincode" className="block text-sm font-medium mb-1">Pincode</label>
                                            <input
                                                id="pincode"
                                                maxLength="6"
                                                {...register("pincode")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                placeholder="Enter pincode"
                                            />
                                            {errors.pincode && <p className="text-red-500 text-sm mt-1">{errors.pincode.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="city" className="block text-sm font-medium mb-1">City</label>
                                            <input
                                                id="city"
                                                {...register("city")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                onChange={handleInputChange}
                                                placeholder="City"
                                            />
                                            {errors.city && <p className="text-red-500 text-sm mt-1">{errors.city.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="state" className="block text-sm font-medium mb-1">State</label>
                                            <input
                                                id="state"
                                                {...register("state")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                onChange={handleInputChange}
                                                placeholder="State"
                                            />
                                            {errors.state && <p className="text-red-500 text-sm mt-1">{errors.state.message}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row gap-2 font-sans w-full">
                                    <div className="px-8 pt-6 pb-8 mb-4 flex-1  shadow-custom-light dark:shadow-custom-dark rounded-custom">
                                        <h2 className="text-xl font-bold mb-4 text-custom-blue flex items-center">
                                            <FaShoppingCart className="mr-2 text-custom-blue" />
                                            Order Details
                                        </h2>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                            <div>
                                                <label htmlFor="orderId" className="block text-sm font-medium mb-1">Order ID</label>
                                                <input
                                                    id="orderId"
                                                    {...register("orderId")}
                                                    className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                    type="text"
                                                    placeholder="Enter Order ID"
                                                />
                                                {errors.orderId && <p className="text-red-500 text-sm mt-1">{errors.orderId.message}</p>}
                                            </div>
                                            <div>
                                                <label htmlFor="invoiceAmount" className="block text-sm font-medium mb-1">Invoice Amount</label>
                                                <input
                                                    id="  "
                                                    {...register("invoiceAmount")}
                                                    className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                    type="text"
                                                    placeholder="Total Amount"
                                                />
                                                {errors.invoiceAmount && <p className="text-red-500 text-sm mt-1">{errors.invoiceAmount.message}</p>}
                                            </div>
                                            <div>
                                                <label htmlFor="paymentMode" className="block text-sm font-medium mt-4 mb-1">Payment Mode</label>
                                                <select
                                                    id="paymentMode"
                                                    {...register("paymentMode")}
                                                    className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                // onChange={handleInputChange}

                                                >
                                                    {/* <option value="">Select Payment Mode</option> */}
                                                    <option value="prepaid">Prepaid</option>
                                                    <option value="cod">COD</option>

                                                </select>
                                                {errors.paymentMode && <p className="text-red-500 text-sm mt-1">{errors.paymentMode.message}</p>}
                                            </div>

                                            <div>
                                                <label htmlFor="codAmount" className="block text-sm mt-4 font-medium mb-1">Amount</label>
                                                <input
                                                    id="codAmount"
                                                    {...register("codAmount")}
                                                    className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                    type="text"
                                                    placeholder="Amount"
                                                    disabled={paymentMode !== "cod"}
                                                />
                                                {errors.codAmount && <p className="text-red-500 text-sm mt-1">{errors.codAmount.message}</p>}
                                            </div>
                                            <div className='w-[450px]'>
                                                <label htmlFor="productDes" className="block text-sm mt-4 font-medium mb-1">Product Descripition</label>
                                                <input
                                                    id="productDes"
                                                    {...register("productDes")}
                                                    className={`p-2 appearance-none shadow-custom-light rounded-custom border md:w-full w-[62%] focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                    type="text"
                                                    placeholder="Product Description"
                                                />
                                                {errors.productDes && <p className="text-red-500 text-sm mt-1">{errors.productDes.message}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-8 pt-6 pb-8 mb-4 flex-1 font-sans dark:shadow-custom-dark  shadow-custom-light rounded-custom">
                                        <h2 className="text-xl font-bold mb-4 text-custom-blue flex items-center">
                                            <FaBox className="mr-2 text-custom-blue" />
                                            Box Details (in Grams)
                                        </h2>
                                        <div className="grid grid-cols-1 gap-2">
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4">
                                                <div>
                                                    <label htmlFor="weight" className="block text-sm font-medium mb-1">Weight</label>
                                                    <input
                                                        id="weight"
                                                        type="number"
                                                        step="0.01"
                                                        {...register("weight")}
                                                        className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                        placeholder="Weight"
                                                    />
                                                    {errors.weight && <p className="text-red-500 text-sm mt-1">{errors.weight.message}</p>}
                                                </div>
                                                <div>
                                                    <label htmlFor="boxQty" className="block text-sm font-medium mb-1">Box Quantity</label>
                                                    <input
                                                        id="boxQty"
                                                        value={1}
                                                        disabled
                                                        // {...register("boxQty")}
                                                        className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                        type="number"
                                                        step="0.01"
                                                        placeholder="Box Quantity"
                                                    />
                                                    {/* {errors.boxQty && <p className="text-red-500 text-sm mt-1">{errors.boxQty.message}</p>} */}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-4">
                                                <div>
                                                    <label htmlFor="length" className="block text-sm font-medium mb-1">Length</label>
                                                    <input
                                                        id="length"
                                                        {...register("length")}
                                                        className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                        type="number"
                                                        step="0.01"
                                                        placeholder="Length"
                                                    />
                                                    {errors.length && <p className="text-red-500 text-sm mt-1">{errors.length.message}</p>}
                                                </div>
                                                <div>
                                                    <label htmlFor="width" className="block text-sm font-medium mb-1">Width</label>
                                                    <input
                                                        id="width"
                                                        {...register("width")}
                                                        className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                        type="number"
                                                        step="0.01"
                                                        placeholder="Width"
                                                    />
                                                    {errors.width && <p className="text-red-500 text-sm mt-1">{errors.width.message}</p>}
                                                </div>
                                                <div>
                                                    <label htmlFor="height" className="block text-sm font-medium mb-1">Height</label>
                                                    <input
                                                        id="height"
                                                        {...register("height")}
                                                        className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                        type="number"
                                                        step="0.01"
                                                        placeholder="Height"
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.height && <p className="text-red-500 text-sm mt-1">{errors.height.message}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center mb-3">
                                    <button
                                        type="submit"
                                        className="w-full md:w-[40%] bg-customPurple hover:bg-purple-700 text-white py-2 rounded-md flex items-center justify-center"
                                    >
                                        {isSubmitting ? <Spinner /> : 'Estimate Reverse Shipping Cost'}
                                        <FaCalculator className="ml-2" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ViewRateForword
                    isopen={isPopupOpen}
                    onClose={handleClose}
                    data={tableData}
                    reqBody={reqBody}
                    type="reverse"
                />
                  <UploadBulk
                                isopen={uploadBulkPopupOpen}
                                onClose={handleCloseUploadBulk}
                            />
            </div>
        </>
    )
}
