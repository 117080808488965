

import React, { useState,useEffect} from 'react';
import { FaCheckCircle, FaCircle, FaTruck, FaMapMarkerAlt } from 'react-icons/fa';
import { CiSearch } from "react-icons/ci";
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';
import { useMyContext } from '../../../context/MyContext';
import '../../../css/custom-scrollbar.css';
import axios from 'axios'
import { Base_Url, track } from '../../../config/config';

const OrderTracking = () => {
  const [waybillNumber, setWaybillNumber] = useState();
  const [orderTrackingInfo, setOrderTrackingInfo] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isDarkMode, isToggleSidebar,orderid,setorderId} = useMyContext();
  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (orderid) {
      handleTrack();
    }
  }, [orderid]);

  const handleTrack = async () => {
    const trackingNumber = orderid || waybillNumber;
    if (!trackingNumber) {
      setErrorMessage('Failed to fetch tracking information. Please try again.');
      setOrderTrackingInfo(null);
      setIsExpanded(false);
    } else {
      const token = localStorage.getItem('token');
      const cus_id = localStorage.getItem('cus_id');
      try {
        const response = await axios.post(Base_Url + track, {
        // const response = await axios.post("http://192.168.1.36/backendci4/api/track", {
          order_id: waybillNumber || orderid,
          shipment_type: 'Forward'
        }, {
          headers: {
            // 'token': "eyJ1c2VyX2lkIjoiNTkiLCJleHBpcmVzX2F0IjoxNzI4NTc0MjE3fQ==",
            'token':token,
            'cus_id': cus_id,
            'Content-Type': 'application/json'
          }
        });
        setOrderTrackingInfo(response.data.data.ShipmentData[0].Shipment);
        setIsExpanded(true);
        setErrorMessage('');
        setorderId(null);  
      } catch (error) {
        setErrorMessage('Failed to fetch tracking information. Please try again.');
        setOrderTrackingInfo(null);
        setIsExpanded(false);
        console.error('Error fetching tracking information:', error.response ? error.response.data : error.message);
      }
    }
  };

  const getStepStatusIcon = (step) => {
    const deliveredSteps = ['delivered'];
    const inProgressSteps = ['orderReceived', 'processingInDubai', 'readyToBeShipped', 'shipped', 'arrivedInRiyadh', 'pickedUpByARAMEX', 'arrivedInJeddah', 'outForDelivery'];

    if (deliveredSteps.includes(step)) {
      return <FaCheckCircle className="text-green-500 w-6 h-6" />;
    }

    if (inProgressSteps.includes(step)) {
      return (
        <div className="relative inline-block">
          <svg className="w-7 h-7" viewBox="0 0 28 28">
            <circle cx="14" cy="14" r="13" fill="#EDE9FE" stroke="#8B5CF6" strokeWidth="2" />
          </svg>
          <FaTruck className="text-customPurple absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3.5 h-3.5" />
        </div>
      );
    }

    return <FaCircle className="text-gray-400 w-6 h-6" />;
  };

  const getDeliveryDate = () => {
    if (orderTrackingInfo) {
      return orderTrackingInfo;
    }
    return 'N/A';
  };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768
                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>
            <Sidebar />
          </div>
        )}
        <div className="dark:bg-[rgba(1,14,78,0.96)] p-8 w-full mr-4 ml-4 mt-4 mb-4 md:w-[40%] md:mr-44  mx- md:ml-44 auto shadow-custom-light dark:shadow-custom-dark rounded-custom overflow-auto custom-scrollbar ">
          <h1 className="text-2xl font-bold dark:text-white mb-6 text-center text-customPurple ">Shipment Tracking</h1>
          <div className="mb-6">
            <label htmlFor="waybillNumber" className="block font-bold mb-2 dark:text-white">
              Enter Order Id
            </label>
            <input
              type="text"
              id="waybillNumber"
              value={waybillNumber}
              onChange={(e) => setWaybillNumber(e.target.value)}
              className="w-full px-4 py-2 border dark:bg-[rgba(1,14,78,0.96)] dark:text-white  border-gray-300 rounded-custom shadow-custom-light dark:shadow-custom-dark focus:outline-none focus:ring-2 focus:ring-customPurple focus:border-purple-700  "
            />
            <button
              onClick={handleTrack}
              className="bg-customPurple hover:bg-purple-700 text-white font-bold dark:text-white py-2 px-4 rounded mt-4 w-full flex items-center justify-center"
            >
              <CiSearch className="mr-2" />
              Track My Shipments
            </button>
            {errorMessage && (
              <p className="text-red-500 mt-4">{errorMessage}</p>
            )}
          </div>

          {orderTrackingInfo && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h2 className="text-lg font-bold dark:text-white mb-4">Tracking Details</h2>
                <div className="space-y-4">
                  <div className="relative">
                    <div className="relative">
                      <div className="relative">
                        {orderTrackingInfo.Scans && orderTrackingInfo.Scans.length > 0 ? (
                          <div className="flex flex-col items-start">
                            {orderTrackingInfo.Scans.map((scan, index) => {
                              return (
                                <div key={index} className="flex flex-col mb-4 relative">
                                  {/* Circle Indicator */}

                                  <div className={` w-6 h-6 rounded-full ${index === orderTrackingInfo.Scans.length - 1 ? 'bg-green-500' : 'bg-gray-300'}`}>
                                    {index === orderTrackingInfo.Scans.length - 1
                                      ? getStepStatusIcon('orderReceived')
                                      : getStepStatusIcon('delivered')}
                                  </div>

                                  <div className=" ml-8 -mt-6 cursor-pointer " >
                                    <p className="font-bold dark:text-white">{scan.ScanDetail.Scan}</p>
                                    <p className="dark:text-white">{scan.ScanDetail.ScannedLocation}</p>
                                    <p className="text-sm text-gray-400">{new Date(scan.ScanDetail.StatusDateTime).toLocaleString()}</p>
                                  </div>
                                  {/* Vertical line connecting steps */}
                                  {index < orderTrackingInfo.Scans.length - 1 && (
                                    <div className="absolute left-3 top-6 w-px h-28 bg-purple-700"></div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <p className="dark:text-white">No tracking information available.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:col-span-1">
                <hr className="my-6 border-gray-300 dark:border-gray-700" />
                <h2 className="text-lg font-bold mb-2 dark:text-white">Delivery Info</h2>
                <p className="font-semibold dark:text-white mb-2">{orderTrackingInfo.Status.Status}</p>
                <div className="flex items-center">
                  <FaMapMarkerAlt className="text-customPurple mr-4" />
                  <p className='dark:text-white'>{orderTrackingInfo.Destination}</p>
                </div>
                <div className="flex items-center mt-4">
                  <p className='dark:text-white'>Estimated Delivery Date: {orderTrackingInfo.Status.ReceivedBy}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;
